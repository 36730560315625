import { LatLngExpression } from 'leaflet'
import {
  COLOR_VESSEL_MOORED,
  COLOR_VESSEL_UNDERWAY,
  COLOR_VESSEL_ANCHORED,
  COLOR_VESSEL_UNKNOWN,
  COLOR_BARGE
} from './Map.constants'
import { IPositionOfTransponder } from '../../interfaces/IVesselStatus'
import { V1VesselStatus } from '../../interfaces/IVesselPosition'

export const MARKER_STATUS_ANCHORED = 'ANCHORED'
export const MARKER_STATUS_MOORED = 'MOORED'
export const MARKER_STATUS_UNDERWAY = 'UNDERWAY'
export const MARKER_STATUS_UNKNOWN = 'UNKNOWN'

export type MapMarkerStatus = typeof MARKER_STATUS_UNDERWAY | typeof MARKER_STATUS_MOORED | typeof MARKER_STATUS_ANCHORED | typeof MARKER_STATUS_UNKNOWN

export interface ICommonMapMarkerData {
  center: LatLngExpression
  radius: number
  color: string
  mmsi: number | string       // TODO: Change name as barges use mmsi field for their id
  status: MapMarkerStatus
  name?: string
  callSign?: string
  imo?: string
  shipType?: string
}

export interface IBargeMapMarkerData extends ICommonMapMarkerData {
  type: 'barge'
}

export interface ICircleMapMarkerData extends ICommonMapMarkerData {
  type: 'circle'
}

export interface IMarkerMapMarkerData extends ICommonMapMarkerData {
  type: 'marker'
  trueHeading?: number
}

export interface IShipShapeMapMarkerData extends ICommonMapMarkerData {
  type: 'shipShape'
  trueHeading?: number
  positionOfTransponder?: IPositionOfTransponder
}

export type IMapMarkerData = ICircleMapMarkerData | IMarkerMapMarkerData | IShipShapeMapMarkerData | IBargeMapMarkerData

export const markerStatus = (vessel: V1VesselStatus | undefined | null | false): MapMarkerStatus => {
  if (vessel && vessel.status) {
    switch (vessel.status) {
      case 'Moored':
        return MARKER_STATUS_MOORED
      case 'UnderwayUsingEngine':
        return MARKER_STATUS_UNDERWAY
      case 'AtAnchor':
        return MARKER_STATUS_ANCHORED
      default:
        return MARKER_STATUS_UNKNOWN
    }
  } else {
    return MARKER_STATUS_UNKNOWN
  }
}

export const colorStatus = (vessel: V1VesselStatus): string => {
  if (vessel && vessel.status) {
    switch (vessel.status) {
      case 'Moored':
        return COLOR_VESSEL_MOORED
      case 'UnderwayUsingEngine':
        return COLOR_VESSEL_UNDERWAY
      case 'AtAnchor':
        return COLOR_VESSEL_ANCHORED
      default:
        return MARKER_STATUS_UNKNOWN
    }
  }
  else {
    return COLOR_VESSEL_UNKNOWN
  }
}

function toCommonMapMarkerData(vessel: V1VesselStatus): ICommonMapMarkerData {
  const { mmsi, callSign, imo, shipName, shipType } = vessel
  let radius = 2
  if (vessel.shipDimensions !== undefined) {
    const pos = vessel.shipDimensions
    radius = pos && pos.distanceToStarboard && pos.distanceToPort ? ((pos.distanceToStarboard + pos.distanceToPort) / 12) : 2
  }

  const center: LatLngExpression = (vessel.location && [vessel.location.coordinates[1], vessel.location.coordinates[0]]) || [0, 0]
  if (center && center[0] === 0 && center[1] === 0) {
    console.error('Unknown vessel position', vessel);
  }
  const color = colorStatus(vessel)
  const status = markerStatus(vessel)
  const name = shipName

  return { center, radius, color, mmsi, status, callSign, name, imo, shipType }
}

export function toMapMarkerData(vessel: V1VesselStatus, markerType: 'circle'): IMapMarkerData
export function toMapMarkerData(vessel: V1VesselStatus, markerType: 'barge'): IMapMarkerData
export function toMapMarkerData(vessel: V1VesselStatus, markerType: 'marker', trueHeading: number): IMapMarkerData
export function toMapMarkerData(vessel: V1VesselStatus, markerType: 'shipShape', trueHeading: number, positionOfTransponder?: IPositionOfTransponder): IMapMarkerData

export function toMapMarkerData(vessel: V1VesselStatus, markerType: IMapMarkerData['type'], trueHeading?: number, positionOfTransponder?: IPositionOfTransponder) {
  if (markerType === 'circle') {
    return { ...toCommonMapMarkerData(vessel), type: markerType }
  } else if (markerType === 'marker') {
    return { ...toCommonMapMarkerData(vessel), type: markerType, trueHeading }
  } else if (markerType === 'shipShape') {
    return { ...toCommonMapMarkerData(vessel), type: markerType, trueHeading, positionOfTransponder }
  } else if (markerType === 'barge') {
    return { ...toCommonMapMarkerData(vessel), type: markerType, color: COLOR_BARGE }
  } else {
    const exhaustive: never = markerType
    throw new Error(exhaustive)
  }
}

const RADIUS = 6371000 // earthRadius in meters
const RADPERDEGREE = Math.PI / 180.0

function longitudeAndLatitudeFromBearingAndDistance(longitudeAndLatitude: [number, number], distance: number, bearing: number): [number, number] {
  const startLongitudeInRadians = RADPERDEGREE * longitudeAndLatitude[0]
  const startLatitudeInRadians = RADPERDEGREE * longitudeAndLatitude[1]
  const bearingInRadians = RADPERDEGREE * bearing

  const endLatitudeInRadians = Math.asin(
    Math.sin(startLatitudeInRadians) * Math.cos(distance / RADIUS) + Math.cos(startLatitudeInRadians) * Math.sin(distance / RADIUS) * Math.cos(bearingInRadians)
  )
  const endLongitudeInRadians =
    startLongitudeInRadians +
    Math.atan2(
      Math.sin(bearingInRadians) * Math.sin(distance / RADIUS) * Math.cos(startLatitudeInRadians),
      Math.cos(distance / RADIUS) - Math.sin(startLatitudeInRadians) * Math.sin(endLatitudeInRadians)
    )
  return [endLongitudeInRadians / RADPERDEGREE, endLatitudeInRadians / RADPERDEGREE]
}

// Defines a ship with a pointed bow, handy to see the ship's orientation
const headFactor = 0.8
const unitShipCoordinates: Array<[number, number]> = [[0, 0], [0, headFactor], [0.5, 1], [1, headFactor], [1, 0], [0, 0]]

const stretchX = (factor: number) => ([x, y]: [number, number]): [number, number] => {
  return [x * factor, y]
}

const stretchY = (factor: number) => ([x, y]: [number, number]): [number, number] => {
  return [x, factor * y]
}

const rotateDegrees = (degrees: number) => ([x, y]: [number, number]): [number, number] => {
  return [x * Math.cos(RADPERDEGREE * degrees) - y * Math.sin(RADPERDEGREE * degrees), x * Math.sin(RADPERDEGREE * degrees) + y * Math.cos(RADPERDEGREE * degrees)]
}

const translateX = (delta: number) => ([x, y]: [number, number]): [number, number] => {
  return [x + delta, y]
}

const translateY = (delta: number) => ([x, y]: [number, number]): [number, number] => {
  return [x, y + delta]
}

const toDistanceAndBearingInDegrees = ([x, y]: [number, number]): [number, number] => {
  const distance = Math.sqrt(x * x + y * y)
  const radiansFromEast = Math.atan2(y, x)
  const degreesFromEast = radiansFromEast / RADPERDEGREE
  const degreesFromNorth = degreesFromEast - 90
  return [distance, degreesFromNorth]
}

const longitudeAndLatitudeRelativeTo = (referenceLongitudeAndLatitude: [number, number]) => (coordinatesInMeters: [number, number]): [number, number] => {
  const [distance, bearingInDegrees] = toDistanceAndBearingInDegrees(coordinatesInMeters)
  return longitudeAndLatitudeFromBearingAndDistance(referenceLongitudeAndLatitude, distance, bearingInDegrees)
}

export function getShipCoordinates(
  transponderDistanceToStarboardInMeters: number,
  transponderDistanceToBowInMeters: number,
  transponderDistanceToSternInMeters: number,
  transponderDistanceToPortsideInMeters: number,
  longitudeAndLatitudeTransponder: [number, number],
  headingInDegrees: number
): Array<[number, number]> {
  const widthInMeters = transponderDistanceToPortsideInMeters + transponderDistanceToStarboardInMeters
  const lengthInMeters = transponderDistanceToSternInMeters + transponderDistanceToBowInMeters

  return unitShipCoordinates
    .map(stretchX(widthInMeters))
    .map(stretchY(lengthInMeters))
    .map(translateX(-transponderDistanceToPortsideInMeters))
    .map(translateY(-transponderDistanceToSternInMeters))
    .map(rotateDegrees(headingInDegrees))
    .map(longitudeAndLatitudeRelativeTo(longitudeAndLatitudeTransponder))
}
