import {filterByFlag, interpolatePoint, ITrace, ITracePoint} from '../../../../interfaces/ITrace'
import * as React from 'react'
import {isEqual} from 'lodash'
import Map, {IMapViewport} from '../../../../components/Map/Map'
import {IMapMarkerData} from '../../../../components/Map/Map.utils'
import {NauticalLocations, NauticalLocationTypes} from '../../../../interfaces/IStaticGeoJSON'
import './HistoryMap.css'
import {CircleMarker, Polyline, Popup} from 'react-leaflet'
import {notUndefined} from '../../../../utils/predicates'
import {LatLngExpression} from 'leaflet'
import {IEvent, IPositionOfTransponder} from '../../../../interfaces/IVesselStatus'
import {DEFAULT_VIEWPORT} from '../../../../constants/ports'

interface IHistoryMapProps {
  center: LatLngExpression
  zoomLevel: number
  selectedTime: number
  traces: ITrace[]
  events: IEvent[]
  positionOfTransponder?: IPositionOfTransponder
  eventListMargin: number
  displayRaw: boolean
  displayFilter: boolean
  nauticalTypes: NauticalLocationTypes
  visiblePolygons: NauticalLocations
  toggleNauticalLocation: (locatyionType: string) => void
  onBoundsChange: (bounds: number[][], center: LatLngExpression, zoomLevel: number) => void
}

const nauticalTypesDictionary = [
  {label: 'Anchor area', key: 'anchorArea'},
  {label: 'Berth', key: 'berth'},
  {label: 'Pilot boarding place', key: 'pilotBoardingPlace'},
  {label: 'Port', key: 'port'},
  {label: 'Port basin', key: 'portBasin'},
  {label: 'Quay', key: 'quay'},
  {label: 'Terminal', key: 'terminal'},
  {label: 'Lightering Area', key: 'lighteringArea'},
  {label: 'Custom nautical location', key: 'customNauticalLocation'}
]

const createMarker = (point: ITracePoint, color: string, index: number) => (
  <CircleMarker
    center={point.coordinates ? point.coordinates : [0, 0]}
    radius={2}
    color={color}
    key={index}
  >
    <Popup>
      <p>
        {point.ignored !== undefined ? `ignored: ${point.ignored}` : ''}{point.ignored !== undefined ? <br/> : ''}
        flags: [{(point.flags.join(','))}]<br/>
        speed: {point.speed} <br/>
        heading: {point.heading} <br/>
        {point.courseOverGround ? `COG: ${point.courseOverGround}` : ''}{point.courseOverGround ? <br/> : ''}
        received: {new Date(point.time).toISOString()} < br/>
        system r: {new Date(point.receivedSystem).toISOString()} < br/>
        source: {point.source}<br/>
        accurate: {point.accurate ? 'true' : 'false'}
      </p>
    </Popup>
  </CircleMarker>
)

interface ITracesComponentProps {
  traces: ITrace[]
  positionOfTransponder?: IPositionOfTransponder
  displayRaw: boolean
  displayFilter: boolean
}

/**
 * Static traces
 */
class StaticTracesComponent extends React.Component<ITracesComponentProps> {
  public shouldComponentUpdate = (nextProps: Readonly<ITracesComponentProps>): boolean => !isEqual(nextProps, this.props)

  public render() {

    const rawPolylines = this.props.traces.map((trace, index) => {
      if (!this.props.displayRaw) {
        return undefined
      }
      const positions = trace.rawPoints.map<[number, number]>(point => point.coordinates ? point.coordinates : [0, 0])

      return <Polyline
        key={index}
        positions={positions}
        color={trace.rawTraceColor}
        smoothFactor={0}
        weight={3}
      />
    })


    const filteredPolylines = this.props.traces.map((trace, index) => {
      const positions = trace.points.map(p => filterByFlag(p))
        .filter(notUndefined)
        .map<[number, number]>(point => point.coordinates ? point.coordinates : [0, 0])
      const polylines = <Polyline
        key={index}
        positions={positions}
        color={trace.traceColor}
        smoothFactor={0}
        weight={3}
      />

      return this.props.displayFilter ? polylines : undefined
    })

    const filteredMarkers = this.props.displayFilter ? this.props.traces.map(trace => trace.points
      .map(p => filterByFlag(p))
      .filter(notUndefined)
      .map((point, pIndex) => createMarker(point, trace.traceColor, pIndex))) : undefined

    const rawPositionMarkers = this.props.displayRaw ? this.props.traces.map(trace => trace.rawPoints
        .map((point, pIndex) => createMarker(point, trace.rawTraceColor, pIndex)))
      : undefined

    return (<div>
      {rawPolylines}
      {filteredPolylines}
      {filteredMarkers}
      {rawPositionMarkers}
    </div>)
  }
}

export class HistoryMap extends React.Component<IHistoryMapProps> {
  public shouldComponentUpdate(nextProps: Readonly<IHistoryMapProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    return !isEqual(nextProps, this.props)
  }

  public render() {

    const viewPort: IMapViewport = {
      ...DEFAULT_VIEWPORT,
      center: this.props.center,
      zoom: this.props.zoomLevel
    }

    const markersForTraces = this.props.traces
      .map(trace => ({
        trace,
        interpolatedPoint: interpolatePoint(trace, this.props.selectedTime, this.props.displayRaw)
      }))
      .map(({trace, interpolatedPoint}) => {
        if (interpolatedPoint === undefined) {
          return undefined
        }

        const result: IMapMarkerData = {
          center: interpolatedPoint.coordinates || [0, 0],
          color: trace.markerColor,
          mmsi: trace.mmsiOrBargeId,
          imo: trace.imo,
          callSign: trace.callSign,
          radius: 4,
          status: trace.status,
          type: 'shipShape',
          name: trace.name,
          trueHeading: interpolatedPoint.heading,
          positionOfTransponder: trace.positionOfTransponder || this.props.positionOfTransponder
        }

        return result
      })
      .filter(notUndefined)

    const currentStatus = this.props.traces
      .map(trace => ({
        trace,
        interpolatedPoint: interpolatePoint(trace, this.props.selectedTime, this.props.displayRaw)
      }))
      .map(({trace, interpolatedPoint}) =>
        (interpolatedPoint === undefined) ? undefined : {
          mmsi: trace.mmsiOrBargeId,
          status: trace.status,
          trueHeading: interpolatedPoint.heading,
          speedOverGround: interpolatedPoint.speed

        })
      .filter(notUndefined)

    const {eventListMargin, selectedTime} = this.props
    const events = this.props.events.filter(({eventTimestamp}) =>
      selectedTime - eventListMargin < eventTimestamp && selectedTime + eventListMargin > eventTimestamp)

    return (
      <>
        <div className={'historyMap'}>
          <Map
            markersData={markersForTraces}
            nauticalTypes={this.props.nauticalTypes}
            nauticalLocations={this.props.visiblePolygons}
            viewport={viewPort} onBoundsChange={this.props.onBoundsChange}>
            <StaticTracesComponent traces={this.props.traces} displayRaw={this.props.displayRaw}
                                   displayFilter={this.props.displayFilter}/>
          </Map>
        </div>
        <div className='nauticalLocation'>
          <fieldset>
            <legend>Nautical location types:</legend>
            {nauticalTypesDictionary.map(location => <label key={location.key}>
              <input
                type='checkbox'
                onChange={(e) => this.props.toggleNauticalLocation(location.key)}
                checked={this.props.nauticalTypes[location.key] || false}
              />
              {location.label}
            </label>)}
          </fieldset>
        </div>
        <div>
          <h4>Status</h4>
          <table className={'statusTable'}>
            <thead>
            <tr>
              <th>MMSI</th>
              <th>Status</th>
              <th>Heading</th>
              <th>Speed over ground</th>
            </tr>
            </thead>
            <tbody>
            {currentStatus.map(({mmsi, status, trueHeading, speedOverGround}, index) => <tr key={index}>
              <td>{mmsi}</td>
              <td>{status === undefined ? 'n/a' : status}</td>
              <td>{trueHeading === undefined ? 'n/a' : trueHeading}</td>
              <td>{speedOverGround === undefined ? 'n/a' : speedOverGround}</td>
            </tr>)}
            </tbody>
          </table>
          <h4>Events</h4>
          {!events ? <p>No events available</p> :
            <table className={'statusTable'}>
              <thead>
              <tr>
                <th>Event</th>
                <th>Record time</th>
                <th>Event time</th>
                <th>Source</th>
                <th>Location</th>
              </tr>
              </thead>
              <tbody>
              {events.map(({eventType, recordTime, eventTime, location}, index) => <tr key={index}>
                <td>{eventType}</td>
                <td>{recordTime}</td>
                <td>{eventTime}</td>
                r
                <td>{location && <>
                  {location.type}<br/>
                  {location.name}<br/>
                  {location.geo && JSON.stringify(location.geo)}
                </>}</td>
              </tr>)}
              </tbody>
            </table>
          }
        </div>
      </>
    )
  }
}
