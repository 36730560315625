import * as React from 'react'
import Map from '../../components/Map/Map'
import { IMapMarkerData, toMapMarkerData} from '../../components/Map/Map.utils'
import { fetchStaticGeo } from '../../rest/fetchStaticGeo'
import { IStaticGeoJSON } from '../../interfaces/IStaticGeoJSON'
import { fetchVessels } from '../../rest/fetchVessels'
import { fetchBarges } from '../../rest/fetchBarges'
import { Search } from './components/Search/Search'
import { UPDATE_EXPLORER_PAGE_INTERVAL } from '../../constants/intervals'
import { Header } from '../../components/Header/Header'
import { Page } from '../../components/Page/Page'
import { Content } from '../../components/Content/Content'
import { notUndefined } from '../../utils/predicates'
import {PortSelector} from './components/PortSelector/PortSelector'
import { USHOU, USHOUPolygon, ports, portViewPorts} from '../../constants/ports'
import {PUSH_BARGE_ALL_OPERATORS, SHIP_CATEGORY, SHIP_TYPE_ALL, SHIP_TYPE_PUSH_BARGES, SHIP_TYPE_VESSELS} from '../../constants/other'
import { debounce } from 'lodash'
import {LatLngExpression} from 'leaflet'
import { bargeStatusToVesselStatus, V1BargeStatus, V1VesselStatus } from '../../interfaces/IVesselPosition'
import {tokenStorage} from "../Auth0/TokenStorage";

interface IExplorerContainerState {
  markersData: IMapMarkerData[]
  geoJSON: IStaticGeoJSON | false
  selectedPort: string
  searchAreaPolygon: number[][]
  selectedShipType: string
  selectedPushBargeOperator: string
  shipCategory: string
}

const REFRESH_INTERVAL = 3000

class ExplorerContainer extends React.Component<{}, IExplorerContainerState> {
  public refreshDataInterval: number = 0

  public state: IExplorerContainerState = {
    markersData: [],
    geoJSON: false,
    selectedPort: USHOU,
    searchAreaPolygon: USHOUPolygon,
    selectedShipType: SHIP_TYPE_ALL,
    selectedPushBargeOperator: PUSH_BARGE_ALL_OPERATORS,
    shipCategory: SHIP_CATEGORY
  }
  public componentDidMount() {
    document.title = 'Pronto AIS'

    this.refreshData()
    this.refreshDataInterval = window.setInterval(this.refreshData, UPDATE_EXPLORER_PAGE_INTERVAL)
  }

  public componentWillUnmount() {
    clearInterval(this.refreshDataInterval)
  }

  public render() {
    return (
      <Page>
        <Header right={<Search markersData={this.state.markersData} />} />
        <div><PortSelector onChange={this.onPortChange} /></div>
        <Content>
          <Map markersData={this.state.markersData} geoJSON={this.state.geoJSON} viewport={portViewPorts.get(this.state.selectedPort)} onBoundsChange={ this.onViewPortChange } />
        </Content>
      </Page>
    )
  }

  public onPortChange = (port: string, shipType: string, pushBargeOperator: string, shipCategory: string) => {
    const searchAreaPolygon = ports.get(port)
    if (searchAreaPolygon !== undefined) {
      this.setState({selectedPort: port, searchAreaPolygon: searchAreaPolygon, selectedShipType: shipType, selectedPushBargeOperator: pushBargeOperator, shipCategory: shipCategory}, this.refreshData)
    } else {
      console.warn('Selected port polygon is not found')
    }
  }

  public onViewPortChange = (m: number[][], center: LatLngExpression, zoomLevel: number) => {
    this.setState({searchAreaPolygon: m}, this.refreshWithDebounce)
  }

  public refreshWithDebounce = debounce(() => this.refreshData(), REFRESH_INTERVAL)

  public fetchPositions = (authToken: string, portPolygon: number[][], shipType: string, bargeOperator: string, shipCategory: string): Promise<[V1VesselStatus[],V1BargeStatus[]]> => {
    var result: Promise<[V1VesselStatus[],V1BargeStatus[]]> = Promise.resolve([[],[]]);
    switch (shipType) {
      case SHIP_TYPE_ALL:
        result = Promise.all([
          fetchVessels(authToken, portPolygon, shipCategory),
          fetchBarges(authToken, portPolygon, bargeOperator)
        ])
        break;
      case SHIP_TYPE_PUSH_BARGES:
        result = Promise.all([
          Promise.resolve(new Array<V1VesselStatus>()),
          fetchBarges(authToken, portPolygon, bargeOperator)])
        break;
      case SHIP_TYPE_VESSELS:
        result = Promise.all([
          fetchVessels(authToken, portPolygon, shipCategory),
          Promise.resolve(new Array<V1BargeStatus>())]
        )
        break;
    }
    return result
  }

  public refreshData = () => {
    const auth = tokenStorage.getToken()
    if (auth) {
      this.fetchPositions(auth, this.state.searchAreaPolygon, this.state.selectedShipType, this.state.selectedPushBargeOperator, this.state.shipCategory).then(positions => {
        const vesselPositions = positions[0]
            .map(p => toMapMarkerData(p, 'circle'))
            .filter(notUndefined)
        const bargePositions = positions[1]
            .map(location => bargeStatusToVesselStatus(location))
            .map(location => toMapMarkerData(location, 'barge'))

        this.setState({
          markersData: vesselPositions.concat(bargePositions)
        })
      })

      fetchStaticGeo(auth).then(geoJSON => this.setState({geoJSON}))
    }
  }
}

export default ExplorerContainer
