import * as React from 'react'
import { PositionHistory } from '../../../../interfaces/PositionHistory'
import { IEvent } from '../../../../interfaces/IVesselStatus'
import {
  LineChart,
  ReferenceDot,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer
} from 'recharts'

export interface ISpeedChartProps {
  trace: PositionHistory[]
  currentTime: number
  startTime: number
  endTime: number
  events: IEvent[]
  eventListMargin: number
}

export const SpeedChart = ({ trace, startTime, endTime, currentTime, events, eventListMargin }: ISpeedChartProps) => (
  <ResponsiveContainer width='100%' height={200}>
    <LineChart data={trace.filter(x => !x.ignored)} margin={{ left: 0, top: 0, right: 0, bottom: 0 }}>
      <XAxis dataKey='recordTime' type='number' domain={[startTime, endTime]} tick={false} />
      <YAxis width={50} />
      <CartesianGrid strokeDasharray='3 3' />
      <Line type='monotone' dataKey='speedOverGround' dot={false} isAnimationActive={false} />
      <ReferenceLine x={currentTime} stroke='red' />
      {events.map((event, index) => <ReferenceDot
        x={event.eventTimestamp}
        y={0}
        key={index}
        r={1}
        stroke='red'
      />)}
      <ReferenceArea
        x1={startTime > currentTime - eventListMargin ? startTime : currentTime - eventListMargin}
        x2={endTime < currentTime + eventListMargin ? endTime : currentTime + eventListMargin}
        ifOverflow="extendDomain"
        stroke='red'
        strokeOpacity={0.3}
      />
    </LineChart>
  </ResponsiveContainer>
)
