import * as React from 'react'
import {Dispatch} from 'react'
import {Redirect, RouteComponentProps} from 'react-router-dom'
import {Feature} from 'geojson'

import {fetchVesselStatus, isFetchVesselError} from '../../rest/fetchVesselStatus'
import {VesselDetails} from './components/VesselDetails/VesselDetails'
import {VesselHistoryContainer} from './VesselHistory.container'
import {fetchStaticGeo} from '../../rest/fetchStaticGeo'
import {fetchNauticalLocations} from '../../rest/fetchNauticalLocations'
import {IStaticGeoJSON, NauticalLocationsData, NauticalLocationTypes} from '../../interfaces/IStaticGeoJSON'
import {BackToHome} from './components/BackToHome/BackToHome'
import {UPDATE_VESSEL_PAGE_INTERVAL} from '../../constants/intervals'
import {Page} from '../../components/Page/Page'
import {Header} from '../../components/Header/Header'
import {Content} from '../../components/Content/Content'
import {Grid} from '../../components/Grid/Grid'
import {Tabs} from '../../components/Tabs/Tabs'
import {EventsTableContainer} from './EventsTable.container'
import {ExportTab} from './components/ExportTab/ExportTab'
import {fetchHistory} from '../../rest/fetchHistory'
import {IEvent} from '../../interfaces/IVesselStatus'
import {fetchEvents} from '../../rest/fetchEvents'
import {PositionHistory} from '../../interfaces/PositionHistory'
import {AdditionalVessels} from './components/AdditionalVessels/AdditionalVessels.container'
import {ITrace, toTrace} from '../../interfaces/ITrace'
import {ADDITIONAL_MARKER_COLOR, ADDITIONAL_TRACE_COLOR, RAW_TRACE_COLOR} from '../../components/Map/Map.constants'
import {combinePromises} from '../../utils/promise'
import {V1ExtendedPositionHistory, V1VesselStatus} from '../../interfaces/IVesselPosition'
import {toIVesselQueryParameters, toQueryString} from '../../interfaces/IVesselQueryParameters'
import {createBrowserHistory, LocationDescriptorObject} from 'history'

import {connect} from 'react-redux'
import {Action, AppState, LOAD_TRACE, UPDATE_WINDOW} from '../../reducer'
import {latLng, LatLng, LatLngExpression} from 'leaflet'
import {DEFAULT_VIEWPORT} from '../../constants/ports'
import { debounce } from 'lodash'
import {tokenStorage} from "../Auth0/TokenStorage";

interface IVesselContainerRouteProps {
  mmsi?: string
}

interface IVesselViewProps {
  aisHistory?: V1ExtendedPositionHistory[]
  startTime: Date
  endTime: Date
  onTimeWindowChange: (start: Date, end: Date) => void
  onLoad: (authToken: string, mmsi: string, startTime: Date, endTime: Date) => void
}

interface IVesselContainerState {
  vesselStatus?: V1VesselStatus
  geoJSON?: IStaticGeoJSON
  events: IEvent[]
  additionalMmsis: string[]
  additionalTraces: ITrace[]
  selectedFilter: boolean
  selectedSource: boolean
  selectedTime: Date
  nauticalTypes: NauticalLocationTypes

  nauticalLocations: NauticalLocationsData[]
  viewportCenter?: LatLngExpression
  visiblePolygons: Feature[]
  zoomLevel: number
}

function findCurrentPosition(trace: V1ExtendedPositionHistory[], selectedTime: Date): V1ExtendedPositionHistory | undefined {
  const points = trace

  if (points.length === 0) {
    return undefined
  } else {
    const pointsWithTimeDistance = points
      .map(point => ({point, timeDistance: Math.abs(selectedTime.valueOf() - Date.parse(point.recordTime).valueOf())}))
    pointsWithTimeDistance.sort((l, r) => l.timeDistance - r.timeDistance)
    return pointsWithTimeDistance[0].point
  }
}

function findCurrentCoordinate(selectedTime: Date, trace?: V1ExtendedPositionHistory[]): LatLng | undefined {
  const p = findCurrentPosition(trace || [], selectedTime)
  return (p && p.location) ? latLng(p.location.coordinates[1], p.location.coordinates[0]) : undefined
}


class VesselPage extends React.Component<RouteComponentProps<IVesselContainerRouteProps> & IVesselViewProps, IVesselContainerState> {
  public refreshDataInterval: number = 0

  private refreshData = () => {
    const {startTime, endTime} = this.props
    const auth = tokenStorage.getToken()
    const selectedMMSI = this.props.match && this.props.match.params && this.props.match.params.mmsi
    const {selectedFilter} = this.state

    if (auth && selectedMMSI !== undefined) {
        fetchVesselStatus(auth, selectedMMSI).then(vesselStatus => {
          if (!isFetchVesselError(vesselStatus)) {
            this.setState({vesselStatus})
          }
        })

        this.props.onLoad(auth, selectedMMSI, startTime, endTime)

        fetchEvents(auth, selectedMMSI, startTime.valueOf(), endTime.valueOf()).then(events => {
          this.setState({events})
        })

        // only fetch additional vessel trace
        this.fetchTraces(
            auth,
            [...this.state.additionalMmsis],
            startTime.valueOf(),
            endTime.valueOf(),
            ADDITIONAL_TRACE_COLOR,
            ADDITIONAL_MARKER_COLOR,
            RAW_TRACE_COLOR,
            selectedFilter)
            .then(additionalTraces => this.setState({additionalTraces}))
    }
  }

  constructor(props: RouteComponentProps<IVesselContainerRouteProps> & IVesselViewProps) {
    super(props)
    const currentTime = Date.now()
    const auth = tokenStorage.getToken()
    const query = toIVesselQueryParameters(this.props.location.search)
    const selectedMMSI = this.props.match && this.props.match.params && this.props.match.params.mmsi

    const state: IVesselContainerState = {
      events: [],
      selectedTime: new Date(currentTime),
      additionalMmsis: [],
      additionalTraces: [],
      selectedSource: false,
      selectedFilter: true,
      nauticalTypes: {},
      nauticalLocations: [],
      visiblePolygons: [],
      zoomLevel: DEFAULT_VIEWPORT.zoom
    }

    if (auth && selectedMMSI !== undefined && query !== undefined) {
      state.additionalMmsis = query.additionalMmsis
    }

    this.state = state
  }

  public componentDidMount() {

    fetchStaticGeo(tokenStorage.getToken() || '').then(geoJSON => this.setState({geoJSON}))
    fetchNauticalLocations(tokenStorage.getToken() || '').then(nauticalLocations => this.setState({nauticalLocations}))
    this.refreshData()
    // refresh data every 30 seconds
    this.refreshDataInterval = window.setInterval(this.refreshData, UPDATE_VESSEL_PAGE_INTERVAL)
  }

  public componentWillUnmount() {
    clearInterval(this.refreshDataInterval)
  }

  public toggleNauticalLocation = (locationType: string) => {
    const nauticalTypes = {
      ...this.state.nauticalTypes,
      [locationType]: !this.state.nauticalTypes[locationType]
    }

    const visiblePolygons: Feature[] = this.state.nauticalLocations
      .filter(location => nauticalTypes[location.type] || false)
      .map(({geo, ...props}) => ({
        type: 'Feature',
        geometry: geo,
        properties: props
      }))

    this.setState({
      nauticalTypes,
      visiblePolygons
    })
  }

  public componentDidUpdate() {
    const locationState: LocationDescriptorObject = {
      ...this.props.location,
      search: toQueryString(this.props.startTime.valueOf(), this.props.endTime.valueOf(), this.state.selectedTime.valueOf(), this.state.additionalMmsis)
    }

    createBrowserHistory().replace(locationState)
  }

  public onBoundsChange(bounds: number[][], center: LatLngExpression, zoomLevel: number): void {
    this.setState({ zoomLevel, viewportCenter: center })

  }

  public render() {
    if (this.state.vesselStatus) {
      document.title = `Pronto AIS - ${this.state.vesselStatus.shipName || this.state.vesselStatus.mmsi}`
    }

    const auth = tokenStorage.getToken()
    const selectedMMSI = this.props.match && this.props.match.params && this.props.match.params.mmsi

    if (!selectedMMSI || !auth) {
      return <Redirect to={'/'}/>
    }

    // console.log('Selected time:' + (this.state.selectedTime || Date.now()))

    const center = this.state.viewportCenter || findCurrentCoordinate(this.state.selectedTime, this.props.aisHistory) || DEFAULT_VIEWPORT.center

    const vesselStatus = this.state.vesselStatus
    const content = (vesselStatus && this.state.geoJSON)
      ? (
        <>
          <VesselHistoryContainer
            vesselStatus={vesselStatus}
            history={this.fromVesselPositions(this.props.aisHistory || [])}
            events={this.state.events}
            geoJSON={this.state.geoJSON}
            startTime={this.props.startTime.valueOf()}
            endTime={this.props.endTime.valueOf()}
            selectedTime={this.state.selectedTime.valueOf()}
            onTimeChange={this.onTimeChange}
            additionalTraces={this.state.additionalTraces}
            selectedSource={this.state.selectedSource}
            selectedFilter={this.state.selectedFilter}
            onSelectedFilter={this.onChangeFilter}
            onSelectedSource={this.onChangeSource}
            nauticalTypes={this.state.nauticalTypes}
            visiblePolygons={this.state.visiblePolygons}
            toggleNauticalLocation={this.toggleNauticalLocation}
            onTimeSubmit={this.onTimeSubmit}
            onBoundsChange={this.onBoundsChange.bind(this)}
            center={center}
            zoomLevel={this.state.zoomLevel}
          />
          <Tabs tabs={{
            'Vessel details': <VesselDetails
              callsign={vesselStatus.callSign}
              name={vesselStatus.shipName}
              mmsiOrBargeId={vesselStatus.mmsi}
              imo={vesselStatus.imo}
              eni={vesselStatus.eni}
              draught={vesselStatus.draught}
              length={vesselStatus.length}
              width={vesselStatus.width}
              lastUpdate={vesselStatus.recordTime}
              position={vesselStatus.location}
              speed={vesselStatus.speedOverGround}
              shipType={vesselStatus.shipType}
              destinationPort={vesselStatus.destinationPort}
              destination={vesselStatus.destination}
              eta={vesselStatus.eta}
              courseOverGround={vesselStatus.courseOverGround}
            />,
            'Events': <EventsTableContainer events={this.state.events}/>,
            'Export': <ExportTab
              authToken={auth}
              mmsi={Number(vesselStatus.mmsi)}
              endTime={this.state.selectedTime.valueOf()}
            />,
            'Additional vessels': <AdditionalVessels vessels={this.state.additionalMmsis} onAddVessel={this.addMmsi}
                                                     onRemoveVessel={this.removeMmsi}/>
          }}/>
        </>
      )
      : <h4>Loading...</h4>

    return (
      <Page>
        <Header left={<BackToHome/>}/>
        <Content>
          <Grid>
            {content}
          </Grid>
        </Content>
      </Page>
    )
  }

  public addMmsi = (mmsi: string) => {
    this.setState({additionalMmsis: this.state.additionalMmsis.concat(mmsi)}, () => this.refreshData())
  }

  public removeMmsi = (mmsi: string) => {
    this.setState({additionalMmsis: this.state.additionalMmsis.filter(additionalMmsi => additionalMmsi !== mmsi)}, () => this.refreshData())
  }

  public onTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setSelectedTime(new Date(parseInt(e.target.value, 0)))
  }

  private setSelectedTime = debounce((selectedTime: Date) => {
    this.setState({ selectedTime, viewportCenter: findCurrentCoordinate(selectedTime, this.props.aisHistory) })
  })

  public onTimeSubmit = (startTime: number, endTime: number) => {
    this.props.onTimeWindowChange(new Date(startTime), new Date(endTime))

    const constrain = (t: number, start: number, end: number): number =>
      Math.min(Math.max(t, start), end)

    const constrainSelectedTime = (t: Date, start: Date, end: Date): Date =>
      new Date(constrain(t.valueOf(), start.valueOf(), end.valueOf()))

    this.setSelectedTime(constrainSelectedTime(this.state.selectedTime, this.props.startTime, this.props.endTime))
  }

  public onChangeSource = (selectedSource: boolean) => {
    this.setState({selectedSource}, () => this.refreshData())
  }

  public onChangeFilter = (selectedFilter: boolean) => {
    this.setState({selectedFilter}, () => this.refreshData())
  }

  public fetchTraces(auth: string, mmsis: string[], from: number, to: number, traceColor: string, markerColor: string, rawTraceColor: string, withFilter: boolean) {
    return Promise.all(mmsis.map(mmsi => this.fetchTrace(auth, mmsi, from, to, traceColor, markerColor, rawTraceColor, withFilter)))
  }

  public fetchTrace(auth: string, mmsi: string, from: number, to: number, traceColor: string, markerColor: string, rawTraceColor: string, withFilter: boolean) {
    return combinePromises({
      trace: fetchHistory(auth, mmsi, from, to),
      status: fetchVesselStatus(auth, mmsi)
    }).then(({status, trace}) => toTrace(status, this.fromVesselPositions(trace), traceColor, markerColor, rawTraceColor))
  }

  public fromVesselPositions(p: V1ExtendedPositionHistory[]): PositionHistory[] {
    return p.map(i => new PositionHistory(i))
  }
}

function mapStateToProps(
  state: AppState
): Pick<IVesselViewProps, 'startTime' | 'endTime' | 'aisHistory'> {
  return {
    startTime: state.vessel.startTime,
    endTime: state.vessel.endTime,
    aisHistory: state.vessel.trace
  }
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): Pick<IVesselViewProps, | 'onLoad' | 'onTimeWindowChange'> {
  return {
    onTimeWindowChange: (startTime: Date, endTime: Date) => dispatch({type: UPDATE_WINDOW, startTime, endTime }),
    onLoad: (auth: string, mmsi: string, startTime: Date, endTime: Date) => dispatch({type: LOAD_TRACE, authToken: auth, mmsi: mmsi, startTime, endTime})
  }
}

const VesselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselPage)

export default VesselContainer
