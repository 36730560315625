import * as React from 'react'
import { IMapMarkerData } from '../../../../components/Map/Map.utils'
import './Search.css'
import { Link } from 'react-router-dom'

interface ISearchProps {
  markersData: IMapMarkerData[]
}

interface ISearchState {
  query: string
}

export class Search extends React.Component<ISearchProps, ISearchState> {
  public state: ISearchState = {
    query: ''
  }

  public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      query: event.target.value
    })
  }

  public render() {
    const suggestions = this.renderSuggestions()
    const isLoading = this.props.markersData.length === 0
    const placeholder = isLoading ? 'Loading vessels...' : 'Search for ships or MMSIs...'

    return (
      <div className={'searchWrapper'}>
        <input type={'text'} value={this.state.query} onChange={this.onChange} disabled={isLoading} placeholder={placeholder} />
        {suggestions}
      </div>
    )
  }

  public renderSuggestions() {
    if (this.state.query === '' || this.state.query.length < 3 || this.props.markersData.length === 0) {
      return null
    }

    const matches = this.props.markersData.filter(data => {
      const query = this.state.query.toLowerCase()
      const vesselName = data.name && data.name.toLowerCase()
      const mmsi = data.mmsi.toString()

      const matchesName = vesselName && vesselName.indexOf(query) > -1
      const matchesMmsi = mmsi.indexOf(query) > -1

      return matchesName || matchesMmsi
    }).slice(0, 20)

    return (
      <div className={'suggestions'}>
        {
          matches.map(data => (
            <div key={data.mmsi} className={'suggestion'}>
              <Link className={'title'} to={'/vessel/' + data.mmsi}>
                {data.name || 'UNKNOWN NAME'} - {data.mmsi}
              </Link>
            </div>
          ))
        }
      </div>
    )
  }
}
