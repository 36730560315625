export const SHIP_TYPE_ALL = 'All'
export const SHIP_TYPE_PUSH_BARGES = 'Push barges'
export const SHIP_TYPE_VESSELS = 'Vessels'

export const PUSH_BARGE_ALL_OPERATORS = 'All Barge Operators'

export const PUSH_BARGE_OPERATORS = [
    PUSH_BARGE_ALL_OPERATORS,
    'Agrifirm',
    'ARA',
    'Rederij de Jong - Bakken',
    'CDC',
    'De Grave-Antverpia'
]

export const SHIP_CATEGORY = 'All types of Ships'

export const SHIP_CATEGORIES = [
    SHIP_CATEGORY,
    'CARGO',
    'DIVING_OPS',
    'DREDGING',
    'FISHING',
    'HIGHSPEED',
    'LAW_ENFORCEMENT_VESSEL',
    'MILITARY_OPS',
    'NOT_AVAILABLE',
    'NOT_PARTIES_TO_AN_ARMED_CONFLICT',
    'OTHER',
    'PASSENGER',
    'PILOT_VESSEL',
    'PLEASURE_CRAFT',
    'PORT_TENDER',
    'SAILING',
    'SEARCH_AND_RESCUE_VESSEL',
    'SPARE',
    'TANKER',
    'TOWING',
    'TUG',
    'VESSEL_WITH_ANTI_POLLUTION_FACILITIES',
    'WING_IN_GROUND'
]

