import * as React from 'react'
import './MmsiInput.css'

export interface IMmsiInputProps {
  mmsi: string
  onChange: (mmsi: string) => void
  onAdd: () => void
}

export const MmsiInput = (props: IMmsiInputProps) => {
  function onChange(e: React.ChangeEvent<any>) {
    props.onChange(e.target.value)
  }

  function onKeyPress(e: React.KeyboardEvent<any>) {
    if (e.key === 'Enter') {
      props.onAdd()
    }
  }
  return (
    <div>
      <div>Enter the MSSI of the vessel you would like to add:</div>
      <div className='mmsi-input'><input type='text' value={props.mmsi} onChange={onChange} onKeyPress={onKeyPress} /><button onClick={props.onAdd}>Add</button></div>
    </div>
  )
}
