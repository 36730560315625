import {PRONTO_AIS_TRACES_BACKEND_URL, PUSHBARGE_BACKEND_URL} from '../constants/env'
import { createHeaders } from './createHeaders'
import { handleResponse } from './handleResponse'
import { EpochMilli } from '../interfaces/IVesselStatus'
import {bargeStatusToExtendedHistory, V1ExtendedPositionHistory} from '../interfaces/IVesselPosition'
import {validMMSI} from '../utils/validMMSI'

export const fetchHistory = (authToken: string, id: string, from: EpochMilli, to: EpochMilli): Promise<V1ExtendedPositionHistory[]> => {
  const isValidMMSI = validMMSI(id)
  const url = isValidMMSI ?
      `${PRONTO_AIS_TRACES_BACKEND_URL}/v1/history-extended`
      :
      `${PUSHBARGE_BACKEND_URL}/v1/barge/${id}/history?from=${from}&&to=${to}`

  const body = [{
    'mmsi': id,
    'from': from,
    'to': to
  }]

  if(isValidMMSI) {
    const options = {
      method: 'POST',
      headers: {
        ...(createHeaders(authToken)),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(url, options).then(handleResponse)
  } else {
    const options = {
      method: 'GET',
      headers: {
        ...(createHeaders(authToken)),
        'Content-Type': 'application/json'
      }
    }
    return fetch(url, options).then(handleResponse).then(res => res.map(bargeStatusToExtendedHistory))
  }
}
