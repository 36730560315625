import 'dotenv/config'
import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'

const getEnvironmentVariable = (name: string): string => {
    const value = process.env[name]

    if (value === undefined) {
        throw new Error(`Key ${name} is not defined in environment`)
    } else if (value === '') {
        throw new Error(`Key ${name} is defined in environment as an empty string`)
    }
    return value
}

export const PRONTO_AIS_TRACES_BACKEND_URL = getEnvironmentVariable('REACT_APP_PRONTO_AIS_TRACES_BACKEND_URL')
export const PRONTO_AIS_GEOFENCING_BACKEND_URL = getEnvironmentVariable('REACT_APP_PRONTO_AIS_GEOFENCING_BACKEND_URL')
export const NAUTICAL_LOCATIONS_URL = getEnvironmentVariable('REACT_APP_NAUTICAL_LOCATIONS_URL')
export const PUSHBARGE_BACKEND_URL = getEnvironmentVariable('REACT_APP_PUSHBARGE_BACKEND_URL')

export const COGNITO_REGION = getEnvironmentVariable('REACT_APP_COGNITO_REGION')
export const COGNITO_CLIENT_ID = getEnvironmentVariable('REACT_APP_COGNITO_CLIENT_ID')

const env = dotenv.config()
dotenvExpand(env)
