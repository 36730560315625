import * as React from 'react'
import {EventsTable} from './components/EventsTable/EventsTable'
import {IEvent} from '../../interfaces/IVesselStatus'

interface IEventsTableContainerProps {
  events: IEvent[]
}

export class EventsTableContainer extends React.Component<IEventsTableContainerProps> {
  public render() {
    return (
      <EventsTable events={this.props.events} />
    )
  }
}
