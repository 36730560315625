import * as React from 'react'
import {IStaticGeoJSON, NauticalLocations, NauticalLocationTypes} from '../../interfaces/IStaticGeoJSON'
import {TimeSlider} from './components/TimeSlider/TimeSlider'
import {HistoryMap} from './components/HistoryMap/HistoryMap'
import {VesselHistory} from './components/VesselHistory/VesselHistory'
import {calculateUpdateRateStats, ITrace, sampleTrace, toTrace} from '../../interfaces/ITrace'
import {IEvent} from '../../interfaces/IVesselStatus'
import {HISTORY_MARKER_COLOR, HISTORY_TRACE_COLOR, RAW_TRACE_COLOR} from '../../components/Map/Map.constants'
import {SpeedChart} from './components/SpeedChart/SpeedCharts'
import {PositionSelector} from './components/PositionSelector/PositionSelector'
import {PositionHistory} from '../../interfaces/PositionHistory';
import {V1VesselStatus} from '../../interfaces/IVesselPosition'
import {LatLngExpression} from 'leaflet'
import {DEFAULT_VIEWPORT} from '../../constants/ports'

const eventListMargin = 1000000

interface IVesselHistoryProps {
  vesselStatus: V1VesselStatus
  history: PositionHistory[]
  events: IEvent[]
  additionalTraces: ITrace[]
  geoJSON: IStaticGeoJSON
  startTime: number
  endTime: number
  selectedTime: number
  onTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedSource: boolean
  selectedFilter: boolean
  onSelectedSource: (selectedSource: boolean) => void
  onSelectedFilter: (selectedFilter: boolean) => void
  nauticalTypes: NauticalLocationTypes
  toggleNauticalLocation: (locatyionType: string) => void
  visiblePolygons: NauticalLocations
  onTimeSubmit: (startTime: number, endTime: number) => void
  onBoundsChange: (bounds: number[][], center: LatLngExpression, zoomLevel: number) => void
  center?: LatLngExpression
  zoomLevel: number
}

export class VesselHistoryContainer extends React.Component<IVesselHistoryProps> {

  public render() {
    const { max, min, average } = calculateUpdateRateStats(this.props.history) || { max: 0, min: 0, average: 0 }

    /**
     * At low zoom levels, we want to reduce the number of points. We use sampleTrace to reduce the number of points
     * depending on the zoom level.
     *
     * The dimensions of the map are exponentially related to the zoom level via `width = C * 2^(-zoomLevel)` where C
     * is some arbitrary constant. Here we calculate a minimum distance (in meters) between two position reports for
     * them to be shown on the map. The magic value has been tuned by hand to have a visually pleasing number of points.
     */
    const minDistance = Math.pow(2, -this.props.zoomLevel) * 1000000

    const traces = [toTrace(this.props.vesselStatus,
      this.props.history,
      HISTORY_TRACE_COLOR,
      HISTORY_MARKER_COLOR,
      RAW_TRACE_COLOR), ...this.props.additionalTraces].map(sampleTrace(minDistance))

    return (
      <VesselHistory>
        <h3>Map &amp; location history</h3>
        <div style={{
          paddingLeft: 45
        }}>
          <TimeSlider
            selectedTime={this.props.selectedTime}
            startTime={this.props.startTime}
            endTime={this.props.endTime}
            onChange={this.props.onTimeChange}
            onSubmit={this.props.onTimeSubmit}
          />
        </div>
        <SpeedChart
          trace={this.props.history}
          currentTime={this.props.selectedTime}
          startTime={this.props.startTime}
          endTime={this.props.endTime}
          events={this.props.events}
          eventListMargin={eventListMargin}
        />
        <hr />
        <PositionSelector
          selectedSource={this.props.selectedSource}
          selectedFilter={this.props.selectedFilter}
          onChangeSelectedSource={this.props.onSelectedSource}
          onChangeWithFilter={this.props.onSelectedFilter}
        />
        <HistoryMap
          center={this.props.center || DEFAULT_VIEWPORT.center}
          zoomLevel={this.props.zoomLevel}
          events={this.props.events}
          selectedTime={this.props.selectedTime}
          traces={traces}
          eventListMargin={eventListMargin}
          displayRaw={this.props.selectedSource}
          displayFilter={this.props.selectedFilter}
          nauticalTypes={this.props.nauticalTypes}
          visiblePolygons={this.props.visiblePolygons}
          toggleNauticalLocation={this.props.toggleNauticalLocation}
          onBoundsChange={this.props.onBoundsChange}
        />
        <div>
          <h4>Update rate stats (seconds)</h4>
          <table>
            <tbody>
              <tr>
                <td>Min: {min / 1000}</td>
                <td>Avg: {Math.round(average / 1000)}</td>
                <td>Max: {max / 1000}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </VesselHistory>
    )
  }
}
