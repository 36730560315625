import * as React from 'react'
import './Header.css'
import { ProntoLogo } from '../ProntoLogo/ProntoLogo'

interface IHeaderProps {
  left?: JSX.Element | JSX.Element[] | string
  right?: JSX.Element | JSX.Element[] | string
}

export const Header = (props: IHeaderProps) => (
  <div className={'header'}>
    <div className={'left'}>
      <div className={'logoWrapper'}>
        <ProntoLogo className={'logo'} />
        <div className={'logoText'}>Pronto AIS</div>
      </div>
    </div>
    <div className={'right'}>
      {props.right || null}
    </div>
  </div>
)
