import * as React from 'react'
import { Point } from 'geojson'
import './VesselDetails.css'

interface IVesselDetailsProps {
  name?: string
  callsign?: string
  mmsiOrBargeId: number | string
  position ?: Point
  imo?: number | string
  eni?: number | string
  draught?: number
  lastUpdate?: number
  speed?: number
  length?: number
  width?: number
  shipType?: string
  destinationPort?: string
  destination?: string
  eta?: number
  courseOverGround?: number
}

export const VesselDetails = (props: IVesselDetailsProps) => (
  <div className={'vesselDetails'}>
    <table>
      <tbody>
      <tr>
        <td>Name</td>
        <td>{props.name || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>Callsign</td>
        <td>{props.callsign || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>MMSI / Barge ID</td>
        <td>{props.mmsiOrBargeId}</td>
      </tr>
      <tr>
        <td>IMO</td>
        <td>{props.imo || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>ENI</td>
        <td>{props.eni || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>Length</td>
        <td>{props.length || 'UNKNOWN'} m</td>
      </tr>
      <tr>
        <td>Width</td>
        <td>{props.width || 'UNKNOWN'} m</td>
      </tr>
      <tr>
        <td>Draught</td>
        <td>{props.draught || 'UNKNOWN'} m</td>
      </tr>
      <tr>
        <td>Last update</td>
        <td>{(props.lastUpdate && new Date(props.lastUpdate).toISOString()) || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>Position</td>
        <td>{(props.position && props.position.coordinates[0]) || 'unknown'}, {(props.position && props.position.coordinates[1]) || 'unknown'}</td>
      </tr>
      <tr>
        <td>Speed</td>
        <td>{props.speed ? (Math.round(props.speed * 100) / 100) : 'UNKNOWN'} knots</td>
      </tr>
      <tr>
        <td>Ship Type</td>
        <td>{props.shipType || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>Destination</td>
        <td>{props.destination || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>Destination Port</td>
        <td>{props.destinationPort || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>CourseOverGround (COG)</td>
        <td>{props.courseOverGround || 'UNKNOWN'}</td>
      </tr>
      <tr>
        <td>ETA</td>
        <td>{props.eta || 'UNKNOWN'}</td>
      </tr>
      </tbody>
    </table>
  </div>
)
