import * as React from 'react'
import Dropdown, {Option} from 'react-dropdown'
import './PortSelector.css'
import {ESALG, NLRTM, USHOU} from "../../../../constants/ports"
import {SHIP_CATEGORIES, SHIP_CATEGORY, SHIP_TYPE_ALL, SHIP_TYPE_PUSH_BARGES, SHIP_TYPE_VESSELS} from "../../../../constants/other"
import {PUSH_BARGE_ALL_OPERATORS, PUSH_BARGE_OPERATORS} from "../../../../constants/other"


const portOptions: string[] = [
    NLRTM, USHOU, ESALG
]

const shipTypeOptions: string[] = [
    SHIP_TYPE_ALL, SHIP_TYPE_PUSH_BARGES, SHIP_TYPE_VESSELS
]

interface IPortSelectorProps {
    onChange: (port: string, shipType: string, pushBargeOperator: string, shipCategory: string) => void
}

interface IPortSelectorState {
    selectedPort: string
    selectedShipType: string
    selectedPushBargeOperator: string
    selectedShipCategory: string
}

export class PortSelector extends React.Component<IPortSelectorProps, IPortSelectorState> {
    constructor (props: IPortSelectorProps) {
        super(props)
        this.state = {
            selectedPort: USHOU,
            selectedShipType: SHIP_TYPE_ALL,
            selectedPushBargeOperator: PUSH_BARGE_ALL_OPERATORS,
            selectedShipCategory: SHIP_CATEGORY
        }

        this.onChangePort = this.onChangePort.bind(this)
        this.onChangeShipType = this.onChangeShipType.bind(this)
        this.onChangePushBargeOperator = this.onChangePushBargeOperator.bind(this)
        this.onChangeShipCategories = this.onChangeShipCategories.bind(this)
    }

    public onChangePort (option: Option) {
        this.setState({selectedPort: option.value, selectedShipType: SHIP_TYPE_ALL, selectedPushBargeOperator: PUSH_BARGE_ALL_OPERATORS})
        this.props.onChange(option.value, SHIP_TYPE_ALL, PUSH_BARGE_ALL_OPERATORS, SHIP_CATEGORY)
    }

    public onChangeShipType (option: Option) {
        this.setState({selectedPort: this.state.selectedPort, selectedShipType: option.value})
        this.props.onChange(this.state.selectedPort, option.value, PUSH_BARGE_ALL_OPERATORS, SHIP_CATEGORY)
    }

    public onChangePushBargeOperator (option: Option) {
        this.setState({selectedPort: this.state.selectedPort, selectedShipType: this.state.selectedShipType, selectedPushBargeOperator: option.value})
        this.props.onChange(this.state.selectedPort, this.state.selectedShipType, option.value, SHIP_CATEGORY)
    }

    public onChangeShipCategories (option: Option) {
        console.log('option is ', option)
        this.setState({selectedPort: this.state.selectedPort, selectedShipType: this.state.selectedShipType, selectedShipCategory: option.value})
        console.log('state is ', this.state)
        this.props.onChange(this.state.selectedPort, this.state.selectedShipType, PUSH_BARGE_ALL_OPERATORS, option.value)
        console.log('props are ', this.props)
    }

    public render() {
        return (
            <section>
                <div className="grid-container">
                    <div style={{width: '220px'}}>
                         <Dropdown options={portOptions} onChange={this.onChangePort} value={this.state.selectedPort} placeholder="Select a port" />
                    </div>
                    <div style={{width: '220px'}}>
                         <Dropdown options={shipTypeOptions} onChange={this.onChangeShipType} value={this.state.selectedShipType} placeholder="Type" />
                    </div>
                    { // conditional rendering
                        this.state.selectedShipType === SHIP_TYPE_PUSH_BARGES  &&
                            <div style={{width: '220px'}}>
                                <Dropdown options={PUSH_BARGE_OPERATORS} onChange={this.onChangePushBargeOperator} value={this.state.selectedPushBargeOperator} placeholder="Type" />
                            </div>
                    }

                    { // conditional rendering
                        this.state.selectedShipType === SHIP_TYPE_VESSELS  &&
                        <div style={{width: '220px'}}>
                            <Dropdown options={SHIP_CATEGORIES} onChange={this.onChangeShipCategories} value={this.state.selectedShipCategory} placeholder="Ship Category" />
                        </div>
                    }
                   
                </div>
            </section>
        )
    }
}
