import { Point } from 'geojson'

export type AisStatus =
  'UnderwayUsingEngine' |
  'AtAnchor' |
  'Moored'
// More, but these are the important ones

export type AISMessageFlag =
  'duplicate' |
  'spike' |
  'ignoredSource' |
  'obsoleteMessage'

// V1 VesselStatus has less fields than V0. Missing fields are only for the backward compatibility here
export interface V1VesselStatus {
  mmsi: string
  imo?: string //V0
  eni?: string  //V0
  callSign?: string //V0
  recordTime: number
  courseOverGround?: number
  heading?: number
  speedOverGround?: number
  location?: Point
  lastLocation?: Point
  lastLocationRecordTime?: number
  shipName?: string
  shipDimensions?: {
    distanceToBow?: number
    distanceToPort?: number
    distanceToStarboard?: number
    distanceToStern?: number
  }
  length?: number //V0
  width?: number  //V0
  draught?: number
  destination?: string
  destinationPort?: string
  eta?: number
  shipType?: string
  status?: AisStatus
  accurate: boolean
}

export interface V1ExtendedPositionHistory {
  mmsiOrBargeId: string
  recordTime: string
  location?: Point
  filteredHeading?: number
  courseOverGround?: number
  heading?: number
  filteredSpeed?: number
  speedOverGround?: number
  accurate: boolean

  source: string

  ignored: boolean
  flags: AISMessageFlag[]

  rawLocation?: Point
  receivedSystem: string
}

export interface V1BargeStatus {
  bargeId: string
  location: Point
  eventTime: number
  recordTime: number
  source: string
  company?: string
  sourceBargeId: string
  speedOverGround?: number
  heading?: number
  length?: number
  width?: number
}

export type IVesselStatus = V1VesselStatus | V1BargeStatus

export function bargeStatusToVesselStatus(barge: V1BargeStatus): V1VesselStatus {
  const mmsiOrBargeId = barge.bargeId
  const name = barge.company
  const location = barge.location
  const timeLastUpdate = barge.recordTime
  const trueHeading = barge.heading
  const speedOverGround = barge.speedOverGround
  const positionAccuracy = true
  const shipType = 'pushBarge' // TODO: populate this with data from pushbarge backend
  const length = barge.length
  const width = barge.width
  const eni = barge.sourceBargeId
  return { mmsi: mmsiOrBargeId, shipName: name, location,
    recordTime: timeLastUpdate, speedOverGround, heading: trueHeading, accurate: positionAccuracy, shipType, length, width, eni }
}

// TODO: This is not a nice way to do this... worth rewriting/ duplicating code so that barge status'/ history is separate from vessel formats?
export function bargeStatusToExtendedHistory(barge: V1BargeStatus): V1ExtendedPositionHistory {
  const mmsiOrBargeId = barge.bargeId
  const location = barge.location
  const recordTime = barge.eventTime.toString()
  const accurate = true
  const source = barge.source
  const ignored = false
  const flags: AISMessageFlag[] = []
  const receivedSystem = barge.recordTime.toString() // TODO: Should recordTime and this be changed?

  return {
    mmsiOrBargeId, location, recordTime, accurate, source, ignored, flags, receivedSystem
  }
}
