import * as React from 'react'
import './AdditionalVessel.css'

export interface IAdditionalVesselProps {
  mmsi: string
  onDelete: () => void
}

export const AdditionalVessel = (props: IAdditionalVesselProps) =>
  <div className='additional-vessel'>
    <div className='mmsi'>{props.mmsi}</div>
    <div className='delete-button' onClick={props.onDelete}>Delete</div>
  </div>
