import { PRONTO_AIS_TRACES_BACKEND_URL } from '../constants/env'
import { V1VesselStatus} from '../interfaces/IVesselPosition'
import { handleResponse } from './handleResponse'
import { createHeaders } from './createHeaders'
import {SHIP_CATEGORY} from '../constants/other'

// TODO: Migrate to a v1 API of ours
export const fetchVessels = (authToken: string, portPolygon: number[][], shipCategory: string): Promise<V1VesselStatus[]> => {
  const queryParams = (shipCategory === SHIP_CATEGORY) ? '' : `shipType=${shipCategory}`
  const url = `${PRONTO_AIS_TRACES_BACKEND_URL}/v1/area/polygon/status?${queryParams}`
  const options = {
    method: 'post',
    headers: {
      ...(createHeaders(authToken)),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ type: 'Polygon', coordinates: [portPolygon] })
  }
  console.warn('Loading vessels')
  return fetch(url, options)
    .then(handleResponse)
}
