import * as React from 'react'
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  CircleMarker,
  GeoJSON,
  Polygon,
  ScaleControl,
  Tooltip
} from 'react-leaflet'
import {LatLngExpression, latLng, LatLngBounds} from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './Map.css'
import {shipIcon, MAP_LAYOUT, COLOR_VESSEL_ANCHORED, COLOR_VESSEL_UNDERWAY, COLOR_VESSEL_LIGHTERING} from './Map.constants'
import {IMapMarkerData, getShipCoordinates} from './Map.utils'
import {
  IStaticGeoJSON,
  NauticalLocations,
  NauticalLocationTypes
} from '../../interfaces/IStaticGeoJSON'
import {DEFAULT_VIEWPORT} from '../../constants/ports'
import {Feature, FeatureCollection} from "geojson"

export interface IMapProps {
  markersData: IMapMarkerData[]
  geoJSON: IStaticGeoJSON | false,
  nauticalLocations: NauticalLocations
  nauticalTypes: NauticalLocationTypes
  viewport?: IMapViewport
  onBoundsChange?: (bounds: number[][], center: LatLngExpression, zoomLevel: number) => void
}

export interface IMapViewport {
  center: LatLngExpression
  zoom?: number
}

const MIN_ZOOM_LEVEL = 15

class Map extends React.Component<IMapProps> {
  public static defaultProps: IMapProps = {
    nauticalLocations: [],
    nauticalTypes: {},
    markersData: [],
    geoJSON: false
  }

  public mapRef: any = React.createRef()

  public render() {
    const markers = this.renderMarkers(this.props.viewport || DEFAULT_VIEWPORT)

    return (
      <div className={'mapContainer'}>
        <LeafletMap ref={this.mapRef} viewport={this.props.viewport || DEFAULT_VIEWPORT}
                    onViewportChanged={this.onViewportChanged} zoomSnap={0.25}>
          <TileLayer
            attribution='&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
            url={MAP_LAYOUT}
          />
          <ScaleControl
            position='bottomleft'
          />
          {this.props.children}
          {this.renderGeoJSON()}
          {this.renderNauticalLocations()}
          {markers}
        </LeafletMap>
      </div>
    )
  }

  public renderMarkers(viewport: IMapViewport) {
    return this.props.markersData.map((data: IMapMarkerData, index: number) => {
      const {name, mmsi, status, callSign, imo, shipType} = data
      const popup = (
        <Tooltip className={'popup'}>
          <p>
            Name: {name || 'UNKNOWN NAME'}<br/>
            MMSI / Barge ID: {mmsi}<br/>
            IMO: {imo}<br/>
            CallSign: {callSign}<br/>
            Status: {status}<br />
            ShipType: {shipType}
          </p>
        </Tooltip>
      )

      if (data.type === 'circle' || data.type === 'barge') {
        return (
          <CircleMarker
            key={index}
            center={data.center}
            color={data.color}
            radius={data.radius}
            onClick={() => window.location.assign('/vessel/' + mmsi)}
          >
            {popup}
          </CircleMarker>
        )
      } else if (data.type === 'marker' || (data.type === 'shipShape' && viewport.zoom && viewport.zoom < MIN_ZOOM_LEVEL)) {
        return (
          <Marker
            key={index}
            icon={shipIcon(data.trueHeading || 0, data.color)}
            onClick={() => window.location.assign('/vessel/' + mmsi)}
            position={data.center}>
            {popup}
          </Marker>
        )
      } else if (data.type === 'shipShape' && data.positionOfTransponder) {
        const p = data.positionOfTransponder
        const center = latLng(data.center)
        if (p.distanceToBow && p.distanceToPort && p.distanceToStern && p.distanceToStarboard && data.trueHeading) {
          const box = getShipCoordinates(p.distanceToStarboard, p.distanceToBow, p.distanceToStern, p.distanceToPort, [center.lng, center.lat], data.trueHeading)
            .map(x => latLng(x[1], x[0]))
          return <Polygon positions={box} color={data.color} key={box.toString()}/>
        } else {
          return <Marker
            key={index}
            onClick={() => window.location.assign('/vessel/' + mmsi)}
            icon={shipIcon(data.trueHeading || 0, data.color)}
            position={data.center}>
            {popup}
          </Marker>
        }
      } else {
        return null
      }
    })
  }

  public renderNauticalLocations() {
    const nauticalLocationsCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: this.props.nauticalLocations
    }

    const getNauticalStyle: any = (feature: any) => ({
      opacity: 0.4,
      fillOpacity: 0.2
    })

    const onEachFeature = (f: Feature, layer: any) => {
      layer.bindPopup('<pre>' + JSON.stringify(f.properties, null, ' ').replace(/[{}"]/g, '') + '</pre>')
    }

    //Component will not get updated unless it gets remounted, so a key is necessary
    const selectionKey =
      Object.keys(this.props.nauticalTypes).filter(key => this.props.nauticalTypes[key as keyof NauticalLocationTypes]).join('-')

    return <div className="nautical-locations">
      <GeoJSON key={selectionKey}
               data={nauticalLocationsCollection}
               style={getNauticalStyle}
               onEachFeature={onEachFeature}
      />
    </div>
  }

  public onViewportChanged = (viewport: any) => { // TODO type?
    const bound = this.mapRef.current.leafletElement.getBounds()
    const center = viewport.center
    const coords = this.createPolygonFromBounds(bound)
    const currentZoomLevel = this.mapRef.current.leafletElement.getZoom()

    if (currentZoomLevel <= MIN_ZOOM_LEVEL) {
      console.warn('Current zoom level is too small , application can not display vessels.', currentZoomLevel)
    }

    if (this.props.onBoundsChange) {
      this.props.onBoundsChange(coords, center, currentZoomLevel)
    }
  }

  public renderGeoJSON() {
    if (!this.props.geoJSON) {
      return null
    }

    // todo: fix types
    const berthsData: GeoJSON.GeoJsonObject = {
      type: 'FeatureCollection',
      features: this.props.geoJSON.berths
    } as any

    const pbpData: GeoJSON.GeoJsonObject = {
      type: 'FeatureCollection',
      features: this.props.geoJSON.pilotboardingplaces
    } as any

    const anchorAreaData: GeoJSON.GeoJsonObject = {
      type: 'FeatureCollection',
      features: this.props.geoJSON.anchorAreas
    } as any

    const lighteringAreaData: GeoJSON.GeoJsonObject = {
      type: 'FeatureCollection',
      features: this.props.geoJSON.lighteringAreas
    } as any

    const mooringSidesData: GeoJSON.GeoJsonObject = {
      type: 'FeatureCollection',
      features: this.props.geoJSON.mooringSides
    } as any

    // todo: fix types
    const getBerthStyle: any = (feature: any) => ({
      opacity: 0.2,
      fillOpacity: 0.2
    })

    const getAnchorAreaStyle: any = (feature: any) => ({
      color: COLOR_VESSEL_ANCHORED,
      opacity: 0.2,
      fillOpacity: 0.2
    })

    const getLighteringAreaStyle: any = (feature: any) => ({
      color: COLOR_VESSEL_LIGHTERING,
      opacity: 0.2,
      fillOpacity: 0.2
    })

    const getPbpStyle: any = (feature: any) => ({
      color: COLOR_VESSEL_UNDERWAY,
      opacity: 0.2,
      fillOpacity: 0.2
    })

    const getMooringSideStyle: any = (feature: any) => ({
      opacity: 1,
      fillOpacity: 1
    })

    // todo: fix types
    const onEachFeature = (f: any, layer: any) => {
      layer.bindPopup(f.properties.id + ' - ' + f.properties.nameLong)
    }

    return <div>
      <GeoJSON data={lighteringAreaData} style={getLighteringAreaStyle} onEachFeature={onEachFeature}/>
      <GeoJSON data={anchorAreaData} style={getAnchorAreaStyle} onEachFeature={onEachFeature}/>
      <GeoJSON data={pbpData} style={getPbpStyle} onEachFeature={onEachFeature}/>
      <GeoJSON data={berthsData} style={getBerthStyle} onEachFeature={onEachFeature}/>
      <GeoJSON data={mooringSidesData} style={getMooringSideStyle} onEachFeature={onEachFeature}/>
    </div>
  }

  public createPolygonFromBounds(latLngBounds: LatLngBounds) {
    // bottom left
    const c1 = [latLngBounds.getSouthWest().lng, latLngBounds.getSouthWest().lat]
    // bottom right
    const c2 = [latLngBounds.getSouthEast().lng, latLngBounds.getSouthEast().lat]
    // top right
    const c3 = [latLngBounds.getNorthEast().lng, latLngBounds.getNorthEast().lat]
    // top left
    const c4 = [latLngBounds.getNorthWest().lng, latLngBounds.getNorthWest().lat]

    return [c1, c2, c3, c4, c1]
  }
}

export default Map
