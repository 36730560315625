import { MmsiInput } from './MmsiInput'
import * as React from 'react'
import './AdditionalVessels.css'
import { AdditionalVessel } from './AdditionalVessel'

export interface IAdditionalVesselsProps {
  vessel: string
  vessels: string[]
  onChangeVessel: (vessel: string) => void
  onAddVessel: () => void
  onRemoveVessel: (mmsi: string) => void
}

export const AdditionalVessels = (props: IAdditionalVesselsProps) => {
  function onRemove(mmsi: string) {
    return () => props.onRemoveVessel(mmsi)
  }

  return (
    <div className='additional-vessels'>
      <MmsiInput mmsi={props.vessel} onAdd={props.onAddVessel} onChange={props.onChangeVessel} />
      <div className='additional-vessel-list'>
        {props.vessels.map((vessel, index) => <AdditionalVessel mmsi={vessel} key={index} onDelete={onRemove(vessel)} />)}
      </div>
    </div>
  )
}
