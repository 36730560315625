import {bargeEventToIEvent, EpochMilli, IEvent} from '../interfaces/IVesselStatus'
import {PRONTO_AIS_TRACES_BACKEND_URL, PUSHBARGE_BACKEND_URL} from '../constants/env'
import {createHeaders} from './createHeaders'
import {handleResponse} from './handleResponse'
import {sortBy} from 'lodash'
import {validMMSI} from '../utils/validMMSI'

export const fetchEvents = (authToken: string, id: string, from: EpochMilli, to: EpochMilli): Promise<IEvent[]> => {
  const isValidMMSI = validMMSI(id)
  const url = isValidMMSI ?
      `${PRONTO_AIS_TRACES_BACKEND_URL}/v1/ais-events/${id}?from=${from}&&to=${to}`
      :
      `${PUSHBARGE_BACKEND_URL}/v1/barge/${id}/events?from=${from}&&to=${to}`

  const options = {
    method: 'GET',
    headers: createHeaders(authToken)
  }

  if(isValidMMSI) {
      return fetch(url, options)
          .then(handleResponse)
          .then(events => sortBy(
              events.map((event: IEvent) => ({
                  ...event,
                  eventTimestamp: +new Date(event.eventTime)
              })),
              event => event.eventTimestamp
          ))
  } else {
      return fetch(url, options)
          .then(handleResponse)
          .then(res => res.map(bargeEventToIEvent))
          .then(events => sortBy(
              events.map((event: IEvent) => ({
                  ...event,
                  eventTimestamp: +new Date(event.eventTime)
              })),
              event => event.eventTimestamp
          ))
  }

}
