import {EpochMilli} from '../interfaces/IVesselStatus'
import {PRONTO_AIS_TRACES_BACKEND_URL} from '../constants/env'
import {createHeaders} from './createHeaders'
import {handleAnyResponse} from './handleResponse'

export type AISRequestBody = Array<{ mmsi: number, from: EpochMilli, to: EpochMilli}>

export const fetchAISExport = (authToken: string, body: AISRequestBody): Promise<Blob> => {
  const url = `${PRONTO_AIS_TRACES_BACKEND_URL}/export`

  const options = {
    method: 'POST',
    headers: {
      ...(createHeaders(authToken)),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }
  return fetch(url, options)
    .then(handleAnyResponse)
    .then(respBody => respBody.blob())
}