import {combineReducers} from 'redux'
import {subDays} from 'date-fns'
import {toIVesselQueryParameters} from './interfaces/IVesselQueryParameters'
import {LatLngExpression} from 'leaflet'
import {V1ExtendedPositionHistory} from './interfaces/IVesselPosition'

export interface VesselViewState {
  mmsi?: string
  selectedTime: Date
  trace?: V1ExtendedPositionHistory[]
  currentPosition?: LatLngExpression
  startTime: Date
  endTime: Date
}

export function emptyVesselViewState(): VesselViewState {

  const query = toIVesselQueryParameters(window.location.search)

  return query !== undefined ? {
    selectedTime: new Date(query.selectedTime),
    startTime: new Date(query.startTime),
    endTime: new Date(query.endTime)
  } : {
    selectedTime: subDays(Date.now(), 0),
    startTime: subDays(Date.now(), 1),
    endTime: subDays(Date.now(), 0)
  }
}

export const TRACE_LOADED = 'TRACE_LOADED'
export const LOAD_TRACE = 'LOAD_TRACE'
export const UPDATE_WINDOW = 'UPDATE_WINDOW'

export interface TraceLoaded {
  type: typeof TRACE_LOADED
  mmsi: string
  history: V1ExtendedPositionHistory[]
}

export interface UpdateWindow {
  type: typeof UPDATE_WINDOW
  startTime: Date
  endTime: Date
}

export interface LoadTrace {
  type: typeof LOAD_TRACE
  authToken: string
  mmsi: string
  startTime: Date
  endTime: Date
}

export type VesselAction = TraceLoaded | LoadTrace | UpdateWindow

export type Action = VesselAction

export function vesselReducer(
  state: VesselViewState = emptyVesselViewState(),
  action: VesselAction
): VesselViewState {
  switch (action.type) {
    case UPDATE_WINDOW:
      return {...state, startTime: action.startTime, endTime: action.endTime}
    case TRACE_LOADED:
      return {
        ...state,
        trace: action.history,
        mmsi: action.mmsi
      }
    default:
      return state
  }
}

export interface AppState {
  vessel: VesselViewState
}

export const appReducer = combineReducers({
  vessel: vesselReducer
})
