import * as React from 'react'
import './TimeSlider.css'
import { EndPoint } from './EndPoint'
import { EndPoints } from './EndPoints'
import { parseFromTimeZone, formatToTimeZone } from 'date-fns-timezone'
import { timeZone, dateFormat } from '../../../../constants/dateTime'

interface ITimeSliderProps {
  startTime: number
  endTime: number
  selectedTime: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (startTime: number, endTime: number) => void
}

export class TimeSlider extends React.Component<ITimeSliderProps, ISetStartEndState> {
  constructor(props: ITimeSliderProps) {
    super(props)
    this.state = {
      startText: formatToTimeZone(props.startTime, dateFormat, { timeZone }),
      endText: formatToTimeZone(props.endTime, dateFormat, { timeZone }),
      error: {
        start: false,
        end: false,
      }
    }
  }

  public render() {
    const props = this.props
    const { startText, endText, error } = this.state

    const selectedDate = formatToTimeZone(props.selectedTime, dateFormat, { timeZone })
    return <div className={'timeSlider'}>
      <button onClick={this.submitStartEnd}>Submit</button>
      <EndPoints>
        <EndPoint type='start'>
          {error.start && <><label>Invalid date</label><br /></>}
          <input type='text' value={startText} onChange={e => {
            e.persist()
            this.setState({
              startText: e.target.value
            })
          }} />
        </EndPoint>
        <EndPoint type='end'>
          {error.end && <><label>Invalid date</label><br /></>}
          <input type='text' value={endText} onChange={e => {
            e.persist()
            this.setState({
              endText: e.target.value
            })
          }} />
        </EndPoint>
      </EndPoints>
      <input
        type={'range'}
        min={props.startTime}
        max={props.endTime}
        value={props.selectedTime}
        onChange={props.onChange}
      />
      Selected time: {selectedDate}
    </div >
  }

  private submitStartEnd = () => {
    const {startText, endText} = this.state
    if(this.validateDate(startText, 'start') && this.validateDate(endText, 'end')) {
      const startDate = parseFromTimeZone(startText, dateFormat, { timeZone }).valueOf()
      const endDate = parseFromTimeZone(endText, dateFormat, { timeZone }).valueOf()
      if (this.validateDateRange(startDate, endDate)) {
        this.props.onSubmit(startDate, endDate)
      }
    }
  }

  private validateDate = (time: string, field: 'start' | 'end') => {
    var date = new Date(time).getTime()
    if (isNaN(date)) {
      this.setState({
              error: {
                ...this.state.error,
                [field]: false
              }
            })
      return false
    } else {
      return true
    }
  }

  private validateDateRange = (start: number, end: number) => {
    if (start >= end) {
      this.setState({
              error: {
                ...this.state.error,
                start : false,
                end: false
              }
            })
      return false
    } else {
      return true
    }
  }
}

interface ISetStartEndState {
  startText: string
  endText: string
  error: {
    start: boolean
    end: boolean
  }
}