export const NLRTM = 'NLRTM'
export const USHOU = 'USHOU'
export const ESALG = 'ESALG'

export const NLRTMPolygon = [
    [-2.643637111126604, 54.4884152633929],
    [-2.099518302478498, 49.59091472033148],
    [-0.8744483555984184, 49.38498070530324],
    [1.694170153808769, 49.79834220506931],
    [2.940821867821954, 50.90503631211072],
    [4.584988655158355, 51.23304450471472],
    [4.976958751858788, 51.85881766554319],
    [4.653083495857395, 52.16314336065598],
    [6.630369776583502, 53.37961385147435],
    [8.222960653505172, 53.17365868799709],
    [9.355112285316579, 54.48210112317167],
    [-2.319824658811992, 54.53190826124881],
    [-2.643637111126604, 54.4884152633929]
]

export const USHOUPolygon = [
    [
        -94.133778,
        23.35303
    ],
    [
        -94.133778,
        27.733885
    ],
    [
        -86.300526,
        27.733885
    ],
    [
        -86.300526,
        23.35303
    ],
    [
        -94.133778,
        23.35303
    ]
]

export const ESALGPolygon = [
    [
        -5.458831787109375,
        36.15755824355528
    ],
    [
        -5.428276062011719,
        36.07449351452833
    ],
    [
        -5.352916717529296,
        36.10750805018494
    ],
    [
        -5.354633331298828,
        36.16185469422557
    ],
    [
        -5.382442474365234,
        36.18236353566431
    ],
    [
        -5.422611236572265,
        36.18194786920369
    ],
    [
        -5.458831787109375,
        36.15755824355528
    ]
]

export const ports = new Map([[NLRTM, NLRTMPolygon], [USHOU, USHOUPolygon], [ESALG, ESALGPolygon]])

// Port ViewPort
const DEFAULT_ZOOM: number = 8

export const USHOU_VIEWPORT: any = {
    center: [28.08409694916474, -93.34259033203126],
    zoom: DEFAULT_ZOOM
}

export const DEFAULT_VIEWPORT: any = USHOU_VIEWPORT

export const NLRTM_VIEWPORT: any = {
    center: [51.8937532, 4.3544154],
    zoom: DEFAULT_ZOOM
}

export const ESALG_VIEWPORT: any = {
    center: [36.136222, -5.433322],
    zoom: DEFAULT_ZOOM
}

export const portViewPorts = new Map([[NLRTM, NLRTM_VIEWPORT], [USHOU, DEFAULT_VIEWPORT], [ESALG, ESALG_VIEWPORT]])
