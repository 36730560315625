export type EpochMilli = number

export interface IEvent {
  uuid: string
  eventType: string
  recordTime: string // ISO 8601
  eventTime: string // ISO 8601
  eventTimestamp: number
  ship: {
    imo?: string
    eni?: string
    mmsi?: string
  }
  location?: {
    type: string
    name?: string
    geo?: {
      coordinates: number[]
      type: string
    }
  }
}

export interface IBargeEvent {
  uuid: string
  eventType: string
  bargeId: string
  source: string
  recordTime: number
  eventTime?: number
  port: string
  location?: {
    type: string
    name?: string
    geo?: {
      coordinates: number[]
      type: string
    }
  }
}

export interface IPositionOfTransponder {
  distanceToBow?: number
  distanceToPort?: number
  distanceToStarboard?: number
  distanceToStern?: number
}

export function bargeEventToIEvent(bargeEvent: IBargeEvent): IEvent {
  const {uuid, eventType, location} = bargeEvent
  const ship = { mmsi: bargeEvent.bargeId }
  const recordTime = bargeEvent.recordTime.toString()
  const eventTime = bargeEvent.eventTime ? bargeEvent.eventTime.toString() : 'undefined'
  const eventTimestamp = bargeEvent.eventTime ? bargeEvent.eventTime : 0
  return {uuid, eventType, recordTime, eventTime, eventTimestamp, ship, location}
}
