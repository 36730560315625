import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './modules/App/App.container'
import registerServiceWorker from './registerServiceWorker'

import {Provider} from 'react-redux'
import { configureStore } from './configureStore';

const app = (
  <Provider store={configureStore()}>
    <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

registerServiceWorker()
