import {NAUTICAL_LOCATIONS_URL} from '../constants/env'
import {handleResponse} from './handleResponse'
import {createHeaders} from './createHeaders'
import {NauticalLocationsData} from '../interfaces/IStaticGeoJSON'

export const fetchNauticalLocations = (authToken: string): Promise<any[]> => {
  console.warn('Loading nautical locations data')
  const options = {
    method: 'GET',
    headers: createHeaders(authToken)
  }

  return fetch(`${NAUTICAL_LOCATIONS_URL}/locations/all`, options)
    .then(handleResponse)
    .then(json => {
      return json
            .filter(({geo}: any) => !!geo)
            .map(({uuid, name, port, geo, length, width, type}: NauticalLocationsData) =>
                ({uuid, name, port, geo, length, width, type}))
    })
}
