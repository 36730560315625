import * as L from 'leaflet'
import './Map.css'

// tslint:disable
export const MAP_LAYOUT = 'https://api.mapbox.com/styles/v1/hbr-pronto/cjc7fak125zkm2sr0sfc3ngxm/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaGJyLXByb250byIsImEiOiJjamM2YnJhczYxZHU2MnhtbXBoeXZyNWM4In0.L0_kvOmX0P--xUZNMw5z5Q'
// tslint:enable

export const shipIcon = (trueHeading: number, color: string) => L.divIcon({
  html: `<div class="marker-ship-icon" style="background-color: ${color}; transform: rotate(${trueHeading}deg)"></div>`,
  className: 'marker-ship-icon-wrapper',
  popupAnchor: [0, -16]
})

export const COLOR_VESSEL_UNDERWAY = 'black'
export const COLOR_VESSEL_MOORED = 'gray'
export const COLOR_VESSEL_ANCHORED = 'gray'
export const COLOR_VESSEL_LIGHTERING = 'light blue'
export const COLOR_VESSEL_UNKNOWN = 'DarkSlateGray'

export const COLOR_BARGE = 'orange'

export const RAW_TRACE_COLOR = 'rgb(0, 139, 0)'
export const HISTORY_TRACE_COLOR = 'rgba(0, 0, 170, 0.8)'
export const HISTORY_MARKER_COLOR = 'rgb(255, 140, 0)'
export const ADDITIONAL_TRACE_COLOR = 'rgba(139, 0, 139, 0.33)'
export const ADDITIONAL_MARKER_COLOR = 'rgb(139, 0, 139)'
