import * as React from 'react'
import { HISTORY_MARKER_COLOR, RAW_TRACE_COLOR } from '../../../../components/Map/Map.constants'

interface IPositionSelectorProps {
    selectedSource: boolean
    selectedFilter: boolean
    onChangeSelectedSource: (positionSource: boolean) => void
    onChangeWithFilter: (withFilter: boolean) => void
}

export const PositionSelector = (props: IPositionSelectorProps) => {
    function onChangeSelectedSource(e: React.ChangeEvent<HTMLInputElement>) {
        props.onChangeSelectedSource(e.target.checked)
    }

    function onChangeWithFilter(e: React.ChangeEvent<HTMLInputElement>) {
        props.onChangeWithFilter(e.target.checked)
    }

    return (
        <div className={'positionSelector'}>
            <label style={{
                color: RAW_TRACE_COLOR
            }}>
                <input type='checkbox' onChange={onChangeSelectedSource} defaultChecked={props.selectedSource} />
                Show raw position (no filter applying, system received time)
            </label>
            <br />
            <label style={{
                color: HISTORY_MARKER_COLOR
            }}>
                <input type='checkbox' onChange={onChangeWithFilter} defaultChecked={props.selectedFilter} />
                Show filtered position trace (received time from AisMessage)
            </label>

        </div>
    )
}
