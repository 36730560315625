import * as React from 'react'
import './EndPoint.css'

export interface IEndPointProps {
  type: 'start' | 'end'
}

export const EndPoint: React.SFC<IEndPointProps> = props => {
  return (
    <div className={`endpoint ${props.type}`}>
      <div className='block'>{props.children}</div>
      <div className='triangle' />
    </div>
  )
}
