import * as React from 'react'
import { Redirect } from 'react-router'
import { PAGES } from '../../constants/pages'
import {tokenStorage} from '../Auth0/TokenStorage'
import {LoginPage} from './LoginPage'

export class LoginContainer extends React.Component {
  public render() {
    if (tokenStorage.isAuthenticated()) {
      return <Redirect to={PAGES.explorer} />
    }

    return <LoginPage />
  }
}
