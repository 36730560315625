import {combineEpics, Epic, ofType} from 'redux-observable'
import {
  Action,
  LOAD_TRACE, LoadTrace, UPDATE_WINDOW,
  TRACE_LOADED, TraceLoaded,
  VesselAction, UpdateWindow
} from './reducer'
import {withLatestFrom, map, mergeMap } from 'rxjs/operators';
import {fetchHistory} from './rest/fetchHistory'
import {tokenStorage} from "./modules/Auth0/TokenStorage";

const loadTraceEpic: Epic<VesselAction> = action$ =>
  action$.pipe(
    ofType<VesselAction, LoadTrace>(LOAD_TRACE),
    mergeMap(action =>
      fetchHistory(action.authToken, action.mmsi, action.startTime.valueOf(), action.endTime.valueOf())
        .then(result => {
          return {type: TRACE_LOADED, history: result, mmsi: action.mmsi} as TraceLoaded
        }))
  )

const updateWindowEpic: Epic<VesselAction> = (action$, state$) =>
  action$.pipe(
    ofType<VesselAction, UpdateWindow>(UPDATE_WINDOW),
    withLatestFrom(state$),
    map(([{startTime, endTime}, state]) => {
      return {
        type: LOAD_TRACE,
        authToken: tokenStorage.getToken() || '',
        startTime,
        endTime,
        mmsi: state.vessel.mmsi
      }

    })
  )

export const rootEpic: Epic<Action> = combineEpics<Action, Action>(
  loadTraceEpic as any,
  updateWindowEpic as any
)
