import * as React from 'react'
import './ExportTab.css'
import { fetchAISExport, AISRequestBody } from '../../../../rest/fetchAISExport'

interface IExportTabProps {
  authToken: string,
  endTime: number,
  mmsi: number
}

interface IExportTabState {
  startTime: string
  mmsis: number[]
  file?: Blob
}

export class ExportTab extends React.Component<IExportTabProps, IExportTabState> {
  constructor(props: IExportTabProps) {
    super(props)

    this.state = {
      startTime: '',
      mmsis: []
    }
  }
  public render() {
    const end = this.props.endTime

    return (
      <div className={'exportTab'}>
        <table>
          <tbody>
            <tr>
              <td>From</td>
              <td><input type='text' size={25} placeholder={new Date(end - 24 * 60 * 60 * 1000).toISOString()} onChange={this.changeStart} className='dateInput' /></td>
            </tr>
            <tr>
              <td>To</td>
              <td>{<input type='text' size={25} value={new Date(end).toISOString()} className='dateInput' disabled={true} />}</td>
            </tr>
            <tr>
              <td>Additional MMSI's</td>
              <td>
                <input type={'text'}
                  size={50}
                  pattern={'\\s([0-9]{9}\\s*,\\s)*([0-9]{9})?\\s'}
                  onChange={this.changeMMSI}
                /> (comma-separated)</td>
            </tr>
            <tr><td colSpan={2}>&nbsp;</td></tr>
            <tr><td>Request:</td><td><pre>{JSON.stringify(this.aisRequestBody(), null, 2)}</pre></td></tr>
            <tr><td colSpan={2}>
              <button onClick={this.downloadAIS} disabled={this.state.startTime === undefined}>Fetch AIS (Avro)</button> <br />
              {this.state.file && this.state.startTime &&
                <a href={URL.createObjectURL(this.state.file)}
                  onClick={this.clearFile}
                  download={new Date(this.state.startTime).toISOString() + '_' + new Date(end).toISOString() + '.avro'}
                >Save File</a>
              }
            </td></tr>
          </tbody>
        </table>
      </div>
    )
  }

  private changeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      startTime: e.target.value
    })
  }

  private clearFile = () => this.setState({ file: undefined })

  private changeMMSI = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      mmsis: e.target.value
        .split(',')
        .map(row => parseInt(row.trim(), 10))
        .filter(mmsi => Number.isInteger(mmsi) && mmsi >= 10000000 && mmsi <= 999999999)
    })
  }

  private aisRequestBody: () => (AISRequestBody | undefined) = () => {
    const start = Date.parse(this.state.startTime)
    if (isNaN(start)) {
      return undefined
    } else {
      const mmsis = [this.props.mmsi].concat(this.state.mmsis)
      return mmsis.map(mmsi => ({
        'mmsi': mmsi,
        'from': start,
        'to': this.props.endTime
      }))
    }

  }

  private downloadAIS = () => {
    const request = this.aisRequestBody()
    if (request !== undefined) {
      fetchAISExport(
        this.props.authToken,
        request
      ).then(resp => {
        this.setState({ file: resp })
      })
    } else {
      alert('could not create request body,is the start date correct: ' + this.state.startTime)
    }
  }
}
