import { PRONTO_AIS_GEOFENCING_BACKEND_URL } from '../constants/env'
import { createHeaders } from './createHeaders'
import { IStaticGeoJSON } from '../interfaces/IStaticGeoJSON'
import { handleResponse } from './handleResponse'

export const fetchStaticGeo = (authToken: string): Promise<IStaticGeoJSON> => {
  // we use short-term session storage for caching the static geo data
  const cachedGeoData = sessionStorage.getItem('geoData')
  if (cachedGeoData) {
    return Promise.resolve(JSON.parse(cachedGeoData))
  }

  console.warn('Loading static geo')

  const options = {
    method: 'GET',
    headers: createHeaders(authToken)
  }

  return fetch(`${PRONTO_AIS_GEOFENCING_BACKEND_URL}/geometry/static.js`, options)
    .then(handleResponse)
    .then(json => {
      // store static geo data in cache
      sessionStorage.setItem('geoData', JSON.stringify(json))
      return json
    })
}
