import * as React from 'react'
import {Page} from '../../components/Page/Page'
import {Header} from '../../components/Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css'
import {tokenStorage} from '../Auth0/TokenStorage'
import {signIn} from '../Auth0/CognitoService'
import history from '../../utils/History'
import {PAGES} from '../../constants/pages'


interface ILoginFormProps {
    error?: string
}

interface ILoginPageState {
    email: string,
    password: string,
    emailError: string,
    passwordError: string
}

export class LoginPage extends React.Component<ILoginFormProps, ILoginPageState> {
    constructor(props: {}) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailError: '',
            passwordError: ''
        }

        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleSignIn = this.handleSignIn.bind(this)
        this.validateForm = this.validateForm.bind(this)
    }

    public handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: e.target.value })
        if (this.state.emailError) { this.setState({ emailError: '' }) }
    }

    public handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: e.target.value })
        if (this.state.passwordError) { this.setState({ passwordError: '' }) }
    }

    public validateForm() {
        let valid = true
        if (!this.state.email) {
            this.setState({ emailError: 'Email is required' })
            valid = false
        } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
            this.setState({ emailError: 'Invalid email address' })
            valid = false
        }

        if (!this.state.password) {
            this.setState({ passwordError: 'Password is required' })
            valid = false
        }

        return valid
    }

    public async handleSignIn(e: { preventDefault: () => void; }) {
        e.preventDefault()
        if (this.validateForm()) {
            try {
                const session = await signIn(this.state.email, this.state.password)
                if (session && typeof session.AccessToken !== 'undefined') {
                    tokenStorage.saveToken(session.AccessToken, session.ExpiresIn)
                    if (tokenStorage.isAuthenticated()) {
                        history.push(PAGES.explorer)
                    } else {
                        console.log('Login Failed')
                    }
                }
            } catch (error) {
                alert(`Sign in failed: ${error}`)
            }
        }
    }

    public render() {
        const { email, password, emailError, passwordError } = this.state

        return (
            <>
                <Page>
                    <Header right={
                        <form onSubmit={this.handleSignIn} className='form-inline'>
                            <div className='row mr-1'>
                                <input
                                    className={emailError ? 'form-control border-danger' : 'form-control border-primary'}
                                    type='email'
                                    id='email'
                                    placeholder='Email'
                                    value={email}
                                    onChange={this.handleEmailChange}
                                />
                                {emailError && <div className='invalid text-danger'>{emailError}</div>}
                            </div>
                            <div className='row mr-1'>
                                <input
                                    className={passwordError ? 'form-control border-danger' : 'form-control border-primary'}
                                    type='password'
                                    id='password'
                                    placeholder='Password'
                                    value={password}
                                    onChange={this.handlePasswordChange}
                                />
                                {passwordError && <div className='invalid text-danger'>{passwordError}</div>}
                            </div>
                            <div className='row mr-1'>
                                <button onSubmit={this.handleSignIn} type='submit' className='btn btn-primary'>Log in</button>
                            </div>
                        </form>
                    }/>
                </Page>
            </>
        )
    }
}

