import * as React from 'react'
import './Tabs.css'

interface ITabsProps {
  tabs: Record<string, JSX.Element>
}

interface ITabsState {
  activeTab: string | false
}

export class Tabs extends React.Component<ITabsProps, ITabsState> {
  public state: ITabsState = {
    activeTab: Object.keys(this.props.tabs) ? Object.keys(this.props.tabs)[0] : false
  }

  public render() {
    return (
      <div className={'tabsContainer'}>
        {this.renderTabMenu()}
        {this.renderContent()}
      </div>
    )
  }

  public renderTabMenu() {
    const tabs = Object.keys(this.props.tabs)

    return (
      <div className={'tabMenu'}>
        {
          tabs.map((title: string, index: number) =>
            <div key={index} className={this.state.activeTab === title ? 'active tabMenuItem' : 'tabMenuItem'} onClick={this.onTabItemClick(title)}>
              {title}
            </div>
          )}
      </div>
    )
  }

  public renderContent() {
    return this.state.activeTab
      ? this.props.tabs[this.state.activeTab]
      : null
  }

  public onTabItemClick = (newActiveTab: string) => () => {
    this.setState({
      activeTab: newActiveTab
    })
  }
}
