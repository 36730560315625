import {PRONTO_AIS_TRACES_BACKEND_URL, PUSHBARGE_BACKEND_URL} from '../constants/env'
import {handleResponse} from './handleResponse'
import {createHeaders} from './createHeaders'
import {bargeStatusToVesselStatus, V1BargeStatus, V1VesselStatus} from '../interfaces/IVesselPosition'
import {validMMSI} from "../utils/validMMSI";

export interface IFetchVesselError {
  error: 'no status'
}

export function isFetchVesselError(vesselStatus: V1VesselStatus | V1BargeStatus | IFetchVesselError): vesselStatus is IFetchVesselError {
  return 'error' in vesselStatus && vesselStatus.error === 'no status'
}

export const fetchVesselStatus = (authToken: string, id: string): Promise<V1VesselStatus | IFetchVesselError> => {
  const isValidMMSI = validMMSI(id)
  const url =  isValidMMSI ?
      `${PRONTO_AIS_TRACES_BACKEND_URL}/v1/vessel/${id}/status`
      :
      `${PUSHBARGE_BACKEND_URL}/v1/barge/${id}/status`

  const options = {
    method: 'GET',
    headers: createHeaders(authToken)
  }
  if(isValidMMSI) {
    return fetch(url, options)
        .then(handleResponse)
  } else {
    return fetch(url, options)
        .then(handleResponse)
        .then(bargeStatusToVesselStatus)
  }

}
