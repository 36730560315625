import { keys } from './obj'

export function combinePromises<O extends object>(op: { [K in keyof O]: Promise<O[K]> }): Promise<O> {
  const keyValuePairPromises = keys(op).map(key => {
    const valuePromise = op[key]
    const keyValuePairPromise = valuePromise.then(value => ({ key, value }))
    return keyValuePairPromise
  })
  const keyValuePairsPromise = Promise.all(keyValuePairPromises)
  const result = keyValuePairsPromise.then(keyValuePairs =>
    keyValuePairs.reduce<Partial<Record<keyof O, O[keyof O]>>>((acc, curr) => {
      acc[curr.key] = curr.value
      return acc
    }, {})
  )
  return result as Promise<O>
}
