import { Action, appReducer } from './reducer'
import { applyMiddleware, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from './epics'
import { composeWithDevTools } from 'redux-devtools-extension'

export function configureStore() {
  const epicMiddleware = createEpicMiddleware<Action>()

  const store = createStore(
    appReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  )

  epicMiddleware.run(rootEpic)

  return store
}
