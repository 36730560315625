import { PUSHBARGE_BACKEND_URL } from '../constants/env'
import { V1BargeStatus } from '../interfaces/IVesselPosition'
import { handleResponse } from './handleResponse'
import { createHeaders } from './createHeaders'
import {PUSH_BARGE_ALL_OPERATORS} from '../constants/other'

export const fetchBarges = (authToken: string, portPolygon: number[][], bargeOperator: string): Promise<V1BargeStatus[]> => {
    var urlBackend: string;
    if (bargeOperator === PUSH_BARGE_ALL_OPERATORS) {
        urlBackend = `${PUSHBARGE_BACKEND_URL}/v1/area/polygon/status`
    } else {
        urlBackend = `${PUSHBARGE_BACKEND_URL}/v1/area/polygon/status?company=${bargeOperator}`
    }
    const options = {
        method: 'post',
        headers: {
            ...(createHeaders(authToken)),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(portPolygon)
    }
    return fetch(urlBackend, options)
        .then(handleResponse)
}
