import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Router } from "react-router";
import ExplorerContainer from '../Explorer/Explorer.container'
import VesselContainer from '../Vessel/Vessel.container'
import { LoginContainer } from '../Login/Login.container'
import { PAGES } from '../../constants/pages'
import {tokenStorage} from "../Auth0/TokenStorage";
import history from "../../utils/History";

const App: React.FC = () => (
  <Router history={history}>
    <Switch>
      <Route path={PAGES.login} component={LoginContainer} />
      <PrivateRoute exact={true} path={PAGES.explorer} component={ExplorerContainer} />
      <PrivateRoute path={PAGES.vessel} component={VesselContainer} />
      <Redirect to={PAGES.explorer} />
    </Switch>
  </Router>
)

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const render = (props: any) => {
    if (tokenStorage.isAuthenticated()) {
      return <Component {...props} />
    } else {
      return <Redirect to={{ pathname: PAGES.login, state: { from: props.location } }} />
    }
  }

  return (
    <Route {...rest} render={render} />
  )
}

export default App
