import {IPositionOfTransponder} from './IVesselStatus'
import {MapMarkerStatus, markerStatus} from '../components/Map/Map.utils'
import {IFetchVesselError, isFetchVesselError} from '../rest/fetchVesselStatus'
import {PositionHistory} from './PositionHistory'
import {AISMessageFlag, V1VesselStatus} from './IVesselPosition'
import getDistance from 'geolib/es/getDistance'
import {foldLeft} from '../utils/functional'

export interface ITracePoint {
  coordinates?: [number, number]
  heading?: number
  courseOverGround?: number
  time: number
  receivedSystem: number
  speed?: number
  accurate?: boolean
  source: string
  ignored?: boolean
  flags: AISMessageFlag[]
}

export interface ITrace {
  points: ITracePoint[]
  rawPoints: ITracePoint[]
  traceColor: string
  markerColor: string
  rawTraceColor: string
  mmsiOrBargeId: number | string
  callSign: string
  name?: string
  status: MapMarkerStatus
  positionOfTransponder?: IPositionOfTransponder,
  imo?: string
}

/**
 * For low zoom levels, reduces the nr of points in the trace to not clutter the screen
 */
export const sampleTrace = (minDistance: number): (trace: ITrace) => ITrace => trace => ({
  ...trace,
  points: sampleTracePoints(minDistance)(trace.points),
  rawPoints: sampleTracePoints(minDistance)(trace.rawPoints)
})

export function sampleTracePoints(minDistance: number): (points: ITracePoint[]) => ITracePoint[] {
  const distance = (p1: [number, number], p2: [number, number]): number =>
    getDistance(
      {longitude: p1[0], latitude: p1[1]},
      {longitude: p2[0], latitude: p2[1]}
    )

  return points => foldLeft<ITracePoint, ITracePoint[]>(points, [])((acc, curr) => {
    if (!acc[0]) {
      return [curr]
    } else {
      const pointIsFarAway = acc[0].coordinates && curr.coordinates && (distance(acc[0].coordinates, curr.coordinates) >= minDistance)
      const isLastPoint = curr === acc[acc.length - 1]

      if (pointIsFarAway || isLastPoint) {
        return [curr].concat(acc)
      } else {
        return acc
      }
    }
  })
}

export function toTrace(
  status: V1VesselStatus | IFetchVesselError,
  vesselPositions: PositionHistory[],
  traceColor: string,
  markerColor: string,
  rawTraceColor: string
): ITrace {
  const points = vesselPositions
    .filter(p => p.ignored === undefined || !p.ignored)
    .sort((p1, p2) => p1.recordTime - p2.recordTime)
    .map<ITracePoint>(p => ({
      coordinates: (p.location && [p.location.coordinates[1], p.location.coordinates[0]]) || undefined,
      time: p.recordTime,
      receivedSystem: p.receivedSystem,
      accurate: p.accurate,
      source: p.source,
      heading: p.filteredHeading || (p.heading !== 511 && p.heading !== null) ? p.heading : p.courseOverGround,
      speed: p.filteredSpeed || p.speedOverGround,
      flags: p.flags
    }))

  const rawPoints = vesselPositions
    .sort((p1, p2) => p1.receivedSystem - p2.receivedSystem)
    .map<ITracePoint>(p => ({
      coordinates: (p.location && [p.location.coordinates[1], p.location.coordinates[0]]) || undefined,
      time: p.recordTime,
      receivedSystem: p.receivedSystem,
      accurate: p.accurate,
      source: p.source,
      heading: p.heading,
      speed: p.speedOverGround,
      courseOverGround: p.courseOverGround,
      flags: p.flags,
      ignored: p.ignored
    }))

  const firstVesselPosition: PositionHistory | false = vesselPositions[0] || false

  if (isFetchVesselError(status)) {
    return {
      points,
      rawPoints,
      traceColor,
      markerColor,
      rawTraceColor,
      mmsiOrBargeId: firstVesselPosition ? firstVesselPosition.mmsi : 'Unknown MMSI',
      callSign: 'Unknown callsign',
      status: 'UNKNOWN'
    }
  } else {
    return {
      points,
      rawPoints,
      traceColor,
      markerColor,
      rawTraceColor,
      mmsiOrBargeId: status.mmsi,
      callSign: status.callSign || 'Unknown callsign',
      status: markerStatus(status),
      positionOfTransponder: status.shipDimensions,
      name: status.shipName,
      imo: status.imo
    }
  }
}

export function filterByFlag(point: ITracePoint): ITracePoint | undefined {
  if (!point.ignored) {
    return point
  } else {
    return undefined
  }
}

export function interpolatePoint(trace: ITrace, time: number, displayRaw: boolean = false): ITracePoint | undefined {
  const points = displayRaw ? trace.rawPoints : trace.points

  if (points.length === 0) {
    return undefined
  } else {
    const pointsWithTimeDistance = points
      .map(point => ({point, timeDistance: Math.abs(time - point.time)}))
    pointsWithTimeDistance.sort((l, r) => l.timeDistance - r.timeDistance)
    return pointsWithTimeDistance[0].point
  }
}

interface IUpdateRateStats {
  min: number
  average: number
  max: number
}

export function calculateUpdateRateStats(trace: PositionHistory[]): IUpdateRateStats | undefined {
  if (trace.length <= 2) {
    return undefined
  }

  const diffs = trace.slice(1).map((point, index) => {
    const previousPoint = trace[index]
    return point.receivedSystem - previousPoint.receivedSystem
  })

  const sum = diffs.reduce((l, r) => l + r)
  return {
    min: Math.min(...diffs),
    average: Math.round(sum / diffs.length),
    max: Math.max(...diffs)
  }
}

