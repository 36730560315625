import queryString from 'query-string'

export interface IVesselQueryParameters {
    startTime: number,
    endTime:number,
    selectedTime: number,
    additionalMmsis: string[]
}

export function toIVesselQueryParameters(query: string): IVesselQueryParameters | undefined {
    const q = queryString.parse(query, {arrayFormat: 'bracket'})
    const start = Number(q.startTime)
    const end = Number(q.endTime)
    const selectedTime = Number(q.selectedTime)
    let m: string[] = []

    if (Array.isArray(q.additionalMmsis)) {
        m = q.additionalMmsis.map(mmsi => '' + mmsi)
    }


    if (isNaN(start) && isNaN(end) && isNaN(selectedTime)) {
        return undefined
    } else {
        return {
            startTime: start.valueOf(),
            endTime: end.valueOf(),
            selectedTime: selectedTime.valueOf(),
            additionalMmsis: m
        }
    }

}

export function toQueryString(startTime: number, endTime: number, selectedTime: number, additionalMmsis: string[]): string {
    const obj = {
        startTime: startTime,
        endTime: endTime,
        selectedTime: selectedTime,
        additionalMmsis: additionalMmsis
    }
    return queryString.stringify(obj, {arrayFormat: 'bracket'})
}