import * as React from 'react'
import { AdditionalVessels as PresentationalComponent } from './AdditionalVessels'

export interface IAdditionalVesselProps {
  vessels: string[]
  onAddVessel: (mmsi: string) => void
  onRemoveVessel: (mmsi: string) => void
}

export interface IAdditionalVesselsState {
  vessel: string
}

export class AdditionalVessels extends React.Component<IAdditionalVesselProps, IAdditionalVesselsState> {

  constructor(props: IAdditionalVesselProps) {
    super(props)
    this.state = { vessel: '' }
  }

  public render() {
    return (
      <PresentationalComponent
        vessel={this.state.vessel}
        vessels={this.props.vessels}
        onChangeVessel={this.updateVessel}
        onAddVessel={this.addVessel}
        onRemoveVessel={this.props.onRemoveVessel}
      />
    )
  }

  private addVessel = () => {
    this.props.onAddVessel(this.state.vessel)
    this.setState({ vessel: '' })
  }

  private updateVessel = (vessel: string) => {
    this.setState({ vessel })
  }
}
