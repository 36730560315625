import {AISMessageFlag, V1ExtendedPositionHistory} from './IVesselPosition'
import { Point } from 'geojson';

export class PositionHistory {
  mmsi: string
  recordTime: number
  location?: Point
  filteredHeading?: number
  courseOverGround?: number
  heading?: number
  filteredSpeed?: number
  speedOverGround?: number
  accurate: boolean

  source: string

  ignored: boolean = false
  flags: AISMessageFlag[] = []

  rawLocation?: Point
  receivedSystem: number
  
    constructor(data: V1ExtendedPositionHistory) {
        this.mmsi = data.mmsiOrBargeId
        this.location = data.location
        this.filteredHeading = data.filteredHeading
        this.recordTime = Date.parse(data.recordTime)
        this.source = data.source
        this.courseOverGround = data.courseOverGround
        this.heading = data.heading
        this.filteredSpeed = data.filteredSpeed
        this.speedOverGround = data.speedOverGround
        this.receivedSystem = Date.parse(data.receivedSystem)
        this.accurate = data.accurate
        this.rawLocation = data.rawLocation
        this.ignored = data.ignored || false
        this.flags = data.flags || []
    }
}
