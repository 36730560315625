import * as React from 'react'
import { Link } from 'react-router-dom'
import { PAGES } from '../../../../constants/pages'
import './BackToHome.css'

export const BackToHome = () => (
  <div className={'link'}>
    <Link to={PAGES.explorer}>
      &lt; Back
    </Link>
  </div>
)
