import * as React from 'react'
import { IEvent } from '../../../../interfaces/IVesselStatus'
import './EventsTable.css'

interface IEventsTableProps {
  events: IEvent[]
}

export const EventsTable = (props: IEventsTableProps) => (
  <div className={'eventsTable'}>
    <table>
      <thead>
        <tr>
          <th>Event</th>
          <th>Record time</th>
          <th>Event time</th>
        </tr>
      </thead>
      <tbody>
        {
          props.events.map((event: IEvent, index: number) => (
            <tr key={index}>
              <td>{event.eventType}</td>
              <td>{event.recordTime}</td>
              <td>{event.eventTime}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
)
